.admin-view{
    background-color: #333333;
    min-height: 100vh;   
}

.admin-home-header,.admin-notifications-header,.admin-quotes-header,.quotes-header{
    height: 100px;
    position: fixed;
    top: 0;
    left: 316px;
    padding: 30px;
    background-color: #333333;
    z-index: 10;
    width: calc(100% - 316px);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}
.quotes-header{
    grid-template-columns: 1fr 1fr 1fr;
}

.admin-ppl-header{
    height: 100px;
    position: fixed;
    top: 0;
    left: 316px;
    padding: 30px;
    background-color: #333333;
    z-index: 10;
    width: calc(100% - 316px);
}

.back-btn{
    height: 48px;
    width: 106px;
    left: 42px;
    top: 27px;
    border-radius: 4px;
    padding: 8px 20px 8px 20px;
}

.quotes-btn{
    padding: 4px 10px 4px 20px;
}
.main-dashboard-project-header{
    position: relative;
}
.admin-project-dashboard{
    position: relative;
    padding: 0 30px;
    padding-top: 40px;
    margin-left: 315px;
    background: #333333;
}
.admin-home,.admin-notifications,.admin-quotes,.admin-quotes-project,.ppl-page,.project-ppl,.allocation-page{
    position: relative;
    padding: 0 30px;
    padding-top: 40px;
    margin-left: 315px;
    background: #333333;
    padding-top: 100px;
}
.admin-profile{
    padding: 0 30px;
    padding-top: 40px;
    margin-left: 315px;
    background: #333333;
}
.admin-page-title{
    font-weight: 600;
font-size: 28px;
line-height: 32px;
font-family: gilroySemiBold;
color: #FFFFFF;
letter-spacing: 0.02em;
text-align: center;
display: contents;

justify-self: baseline;
}

.admin-profile-title{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-family: gilroySemiBold;
    color: #FFFFFF;
    letter-spacing: 0.02em;
    text-align: center;
}

.admin-title{
    /* padding-left: 225px; */
    justify-self: center;
}

.search-form{
 position: relative;
 width: 25vw;
 height: 40px;
 margin-right: 50px;
}

.search-form input{
 padding: 10px 20px;
    padding-left: 42px;
 outline: none;
 border: none;
 background: #4B4B4B;
 font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroyRegular;
letter-spacing: 0.02em;
color: #D6D6D6;
border: 1px solid transparent;
width: 100%;
border-radius: 6px;
}
.search-form input::placeholder{
    color: red;
    font-size: 36px;
}
.search-form input:focus{
    border: 1px solid #01D9AB;
}
.search-form input::placeholder{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroyRegular;
    letter-spacing: 0.02em;
    color: rgba(214, 214, 214, 1);
}
.search-form svg{
 position: absolute;
 top: 50%;
 left: 15px;
 transform: translateY(-50%);
}


.status{
    font-family: gilroyRegular;
    display: flex;
    gap: 3px;
    font-size: 16px;
}

.quotes-footer{
    display: grid;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 60px;
    margin-bottom: 30px;
}

.quotes-footer-btn{
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
}

.quotes-footer-btn div{
    padding: 12px 16px;
    letter-spacing: 0.02em;
}

.project-body{
    background-color: #4B4B4B;
    padding: 30px 58px;
    border-radius: 4px;
}

.project-name{
    font-family: gilroyRegular;
    font-weight: 600;
    font-size: 34px;
    text-transform: capitalize;
    color: #01D9AB;
    text-align: center;
}

.project-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 142px;
    margin: 20px 0;
    justify-content: center;
}

.detail{
    color: #D6D6D6;
    font-size: 18px;
    font-weight: 600;
    font-family: gilroySemiBold;
    margin: 36px 0;
}

.files-link{
    text-decoration: none;
    color: #01D9AB;
}

.detail:last-child{
    margin-bottom: 0;
}

.info{
    margin-left: 5px;
    color: #01D9AB;
}

.view-more{
    color: #D6D6D6;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.documents{
    margin: 20px 0;
}



.files{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.admin-quote-project-title{
    display: flex;
    flex-direction: column;
}
.admin-quote-title{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-family: gilroySemiBold;
    color: #FFFFFF;
    letter-spacing: 0.02em;
    text-align: center;
}
.quotes-table{
    width: 100%;
    background-color: #4B4B4B;
    box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;

margin-bottom: 20px;
}
.quotes-table td{
    text-align: center;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

letter-spacing: 0.02em;
font-family: gilroySemiBold;
color: #D6D6D6;
padding: 10px;
}

.quotes-table tr:first-child td{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */
    padding: 20px;
    letter-spacing: 0.02em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    /* White max */
    font-family: gilroySemiBold;
    color: #FFFFFF;
}
.quotes-table tr td:first-child{
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.quote-project-view{
    text-decoration: none;
    color: #01D9AB;
    cursor: pointer;
}

.quotes-new{    
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
    background: #01D9AB;
    border-radius: 33px;
    padding: 5px 15px;
}
.view-request{
    width: 110px;
    display: flex;
    justify-content: center;
    background: rgba(64, 186, 255, 1);
}
.completed-bg{
    background: rgba(19, 193, 79, 1);
}
.pending-bg{
    background: rgba(255, 150, 52, 1);
}
.active-bg{
    background: rgba(64, 186, 255, 1);
}
.cancelled-bg{
    background: rgba(255, 0, 0, 1);
}
.completed-status{
    color: rgba(19, 193, 79, 1);
}
.pending-status{
    color: rgba(255, 150, 52, 1);
}
.active-status{
    color: rgba(64, 186, 255, 1);
}
.cancelled-status{
    color: rgba(255, 0, 0, 1);
}
.select-project{
    text-decoration: none;
    font-family: gilroySemiBold;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
cursor: pointer;
border-bottom: 1.6px solid transparent;
}
.select-project:hover{
    color: #01D9AB;
    border-bottom: 1.6px solid #01D9AB;
}

.btn-new-project{
    margin-left: auto;
    font-family: Gilroy-Medium;
}

.admin-profile-body{
    display: flex;
    font-family: gilroyRegular;
    margin-left: 90px;
    margin-top: 40px;
    gap: 126px;
}

.profile-details{
    display: flex;
    font-family: gilroyRegular;
    margin-left: 90px;
    margin-top: 40px;
    gap: 226px;

}

.admin-profile-subbody{
    display: flex;
    gap: 150px;
}

.avatar-body{
    display: flex;
    gap: 26.5px;
    margin-top: 20px;
}

.profile-avatar-title{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.profile-avatar-name{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.profile-avatar-designation{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #A2A2A2;
    margin-bottom: 6px;
}

.upload-avatar{
    background: #4B4B4B;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-btn-div{
    display: flex;
    justify-content: end;
    margin-right: 84px;
}

.edit-btn{
    cursor: pointer;
}

.profile-save-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 164px;
}

@media screen and (max-width: 1000px){
    .admin-nav{
        display: none;
    }
    .admin-sm-header{
        display: flex;
        align-items: center;
        margin: 20px ;
        position: relative;
    }
    .header-sm-logo{
        width: 135px;
        margin: 0 auto;
    }
    .admin-home-header,.admin-notifications-header,.admin-quotes-header,.quotes-header,.admin-ppl-header{
        grid-template-columns: 1fr;
        position: static;
        padding: 0 10px;
    }
    .admin-home-header{
        display: flex;
        flex-direction: column-reverse;
        margin: 20px 0;
        gap: 78px;
        width: 100%;
        position: relative;
        left: 0;
        height: auto;
        padding: 0;
    }
    .admin-profile{
    padding: 0 30px;
    padding-top: 40px;
    margin-left: 0px;
    background: #333333;
}
.profile-details{
    display: flex;
    font-family: gilroyRegular;
    margin-left: 20px;
    margin-top: 40px;
    gap: 50px;
}
.admin-profile-body{
    display: flex;
    font-family: gilroyRegular;
    margin-left: 20px;
    margin-top: 40px;
    gap: 126px;
}
    .admin-project-dashboard{
        margin-left: 0;
        padding: 0 10px;
    }
    .admin-notifications-header, .admin-quotes-header{
        display: block;
        margin-bottom: 21px;
        gap: 21px;
        width: 100%;
        position: relative;
        left: 0;
        height: auto;
        margin-top: 50px;
    }
    .quotes-header{
        width: 100%;
        position: relative;
        left: 0;   
        margin-top: 56px;
    }
    .admin-ppl-header{
        display: grid;
        width: 100%;
        justify-content: center;
        margin-bottom: 21px;
    }
    .single-ppl-header{
        margin-top: 60px;
    }
    .back-btn{
        position: absolute;
        top: -90px;
        right: 0;
        left: auto;
        width: 80px;
        height: 40px;
        border-radius: 3px;
        padding: 0;
    }
    @media (max-width: 1000px){
        .back-btn{
            top: -55px;
            right: 12px;
            width: 69px;
            height: 36px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-items: center;
            padding: 10px;
        }
        .back-btn span{
            margin-right: 0;
        }
        .btn-resp{
            gap: 4px;
        }
    }
    .single-ppl-back-btn{
        top: -120px;
    }
    .back-btn span{
        margin-right: 8px;
    }
    .admin-title{
        padding: 0;
    }
    .admin-home,.admin-notifications,.admin-quotes,.admin-quotes-project,.ppl-page,.project-ppl,.allocation-page{
        margin-left: 0;
        padding: 0 10px;
    }
    .admin-home-title{
        top: -100px;
    }
    .admin-page-title{
        font-family: gilroySemiBold;
        font-size: 18px;
        /* font-weight: 600; */
        line-height: 21px;
        letter-spacing: 0.02em;
        position: static;
        display: block;
        color: rgba(214, 214, 214, 1);
        transform: none;
    }
    .search-form{
        width: 100%;
        margin: 0 auto;
    }
    .home-tabs{
        font-size: 14px;
        line-height: 16px;
    }
.sorting-section{
    display: flex;
    justify-content: end;
    margin-top: 10px;
}
.sort-action span{
    display: none;
}
.sort-action .sort-resp{
    display: inline;
}
.documents{
    margin: 20px 0px;
}
.files{
    margin-left: 0px;
    display: block;
}
.file-item{
    margin-bottom: 12px;
}
.project-body{
    padding: 10px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}
.project-name{
    font-size: 24px;
}
.project-details{
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0 0px;
}
.detail{
    margin: 20px 0;
}
.quotes-table td{
    font-size: 14px;
}
.btn-new-project{
    margin: 0 auto;
    position: absolute;
    width: 100%;
    display: grid;
    top: 35px;
    justify-content: center;
}
}
.project-projection-header{
    grid-template-columns: 1fr 2fr 1fr;
}
@media (max-width : 1100px) {
    .project-projection-title{
        font-size: 22px;
    }
}
@media (max-width : 1000px) {
    .project-projection-status{
        margin-bottom: 10px;
    }
    .search-form{
        height: 35px;
        max-height: 35px;
    }
}