.ppl-title{
    display: flex;
    justify-content: center;
    width: 85%;
}
.ppl-content{
    width: 80vw;
    position: relative;
}

.ppl-top-buttons{
    display: flex;
    /* margin-top: 26px; */
    width: 100%;
    justify-content: end;
    gap: 10px;
    position: absolute;
    top: 30px;
    right: 10px;
}
.ppl-table {
    border-collapse: collapse;
    color: #fff;
    margin-bottom: 20px;
    width: 2000px;
    font-size: 16px;
}

.ppl-table thead {
    background-color: #707171;
    color: #fff;
}

.ppl-table th {
    text-align: center;
    padding: 20px;
    font-weight: 400;
    border-right: 0.1px solid #E4E4E4;
    
}
.allocation-th-min{
    min-width: 200px;
}
.ppl-table th:last-child {
    border: none;
}

.ppl-table tbody {
    background-color: #4B4B4B;
}

.ppl-table .first-column {
    text-transform: capitalize;
}
.ppl-table .ppl-col-max{
    max-width:200px;
}
.ppl-table .form-input{
    background-color: rgba(217, 217, 217, 0.12);
}
.ppl-table select.form-input{
    -webkit-appearance: none;
    -moz-appearance: none;
    /* background-image:
    linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%); */
    background-image: url(../../icons/dropdown.svg);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        10px 10px,
        10px 10px,
        5em 5em;
    background-repeat: no-repeat;
}
.ppl-table .form-input-option{
    background-color: rgb(92 92 92);
    color: white;
    padding: 10px 0;
    font-weight: 500;
font-size: 13px;
line-height: 15px;
color: #D6D6D6;

}
.ppl-table td {
    text-align: center;
    padding: 20px;
    border-right: .05em solid #E4E4E4;
}

.ppl-table td:last-child {
    border: none;
}
.ppl-actions{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.ppl-tag{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: capitalize;
    font-weight: 500;
font-size: 13px;
line-height: 15px;
font-family: avenirLight;
}

.ppl-table > thead{
    position: sticky;
    top: 0px;
    z-index: 9;
}

.ppl-payment-header{
    text-align: center;
    font-weight: 600;
font-size: 34px;
line-height: 38px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
text-transform: capitalize;
color: #FFFFFF;
}
.ppl-payment-form{
    width: 60%;
    margin: 0 auto;
    padding: 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 85px;
    grid-row-gap: 30px;
    
}
.ppl-payment-form-border{
    border-bottom: 1px solid #D6D6D6;
}
.ppl-form-actions{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.ppl-proposal{
    background: #4B4B4B;
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
min-height: 100vh;
position: relative;
display: flex;
    flex-direction: column;
}
.ppl-proposal-upload-bg{
    position: absolute;
    height: 100%;
    width: 100%;
    background: #4b4b4bbf;
}
.ppl-proposal-upload{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 40px 0;
    padding: 42px 25px;
    background: #333333;
border: 1px solid rgba(255, 255, 255, 0.12);
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
    width: 429px;
height: 220px;
display: flex;
flex-direction: column;
align-items: center;
}
.ppl-status-dropdown{
    margin: 0 auto;
    width: fit-content;
    border: 2px solid #FFFFFF;
border-radius: 4px;
padding: 8px 20px;
}
.rotate{
    transform: rotate(180deg) translateY(3px);
}
.ppl-status{
    display: flex;
    align-items: baseline;
    color: white;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;
    gap: 8px;
}
.ppl-status-option{
    margin-top: 10px;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: inherit;
letter-spacing: 0.02em;
color: #D6D6D6;
cursor: pointer;
text-transform: capitalize;
}
.allocation-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.allocation-bar-actions{
    display: flex;
    gap:40px
}
.allocation-actions{
 display: flex;
 gap: 10px;
 justify-content: center;
}
.allocation-page .ppl-table{
    width: 100%;
    margin-top: 35px;
}
.allocation-duration{
 display: flex;
 justify-content: space-between;
 margin-top: 15px;
}
.modal-block-user{
    overflow: visible;
}
.date-picker{
    display: flex;
    background: rgba(255, 255, 255, 0.12);
border-radius: 4px;
width: 150px;
padding: 5px 10px;
gap: 10px;
}
.date-picker-title{
    font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */

letter-spacing: 0.02em;

/* White max */

color: #FFFFFF;

}
.date-picker-input{
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    letter-spacing: 0.5px;
font-size: 12px;
line-height: 20px;
color: #D6D6D6;
}
.from_date,.till_date {
    display: none;
}
@media screen and (max-width: 1000px){
    .ppl-title{
        width: 100%;
        top: 10px;
    }
    .single-ppl-title{
        top: -60px;
    }
    .ppl-top-buttons{
        position: static;
        justify-content: center;
    }
    .ppl-top-buttons .btn{
        font-size: 12px;
    }
    .ppl-content{
        width: 100%;
        overflow-x: scroll;
    }
    .ppl-table > thead{
        position: sticky;
        z-index: 9;
        top: 0;
    }
    .allocation-bar{
        flex-direction: column;
        align-items: flex-end;
    }
}
.ppl-body{
    overflow-x: scroll;
}
.allocation-page{
    overflow-x: hidden;
}