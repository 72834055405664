.tutorial-page{
    display: grid;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
}

.tutorial-skip{
    position: absolute;
    bottom: 159px;
    right: 127px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.skip-arrow{
    width: 24px;
    height: 16px;
    margin-left: 5px;
}

.pagination-ellipse{
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 16px;
    margin-bottom: 25px;
}

.tutorials-pagination{
    position: absolute;
    bottom: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.pagination-btn{
    display: flex;
    justify-content: space-between;
}

.prev-btn{
    border-right: 0.5px solid rgba(255, 255, 255, 0.5);
    padding-right: 16px;
}

.next-btn{
    border-left: 0.5px solid rgba(255, 255, 255, 0.5);
    padding-left: 16px;
}

.pagination-btn div{
    cursor: pointer;
    display: flex;
    gap: 5px;
}

.pagination-btn div:hover{
    color: #00D4A8;
}

.pagination-grey{
    color: #D6D6D6;
}

.tutorial-new-project-btn{
    position: absolute;
    width: 100%;
    top: 140px;
    display: flex;
    justify-content: right;
    padding-right: 50px;
}

.tutorial{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.tutorial-navbar{
    margin-left: 0px;
    margin-top: 40px;
}

.tutorial{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 20px;
}

.tutorial-text{
    background: #226DDE;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 36px;
    color: #fff;
}
@media (max-width : 1100px) {
    .tut-resp-2{
        margin-top: 0;
        margin-left: 3rem !important;
    }
    .tut-resp-3{
        margin-top: 0;
        margin-left: 0rem !important;
    }
    .tut-resp-4{
        margin-top: 0;
        margin-left: 0rem !important;
    }
    .tut-resp-5{
        margin-top: 0;
        margin-left: 0rem !important;
    }
    .tut-resp-6{
        margin-top: 0;
        margin-left: 0rem !important;
    }
    .tutorial-text{
        width: 340px;
    }
}
.tutorial-text-heading{
    font-family: gilroyBold;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}

.tutorial-text-footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tutorial-text-footer div{
    cursor: pointer;
}

.tutorial-pointer{
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.tutorial1-text{
    width: 330px;
    position: absolute;
    top: 220px;
    right: 50px;
}

.tutorial1-text::before{
    content: "";
    position: absolute;
    top: -20px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.tutorial-navbar-item{
    background: #fff;
    color: #000;
}

.home-item-tab{
    position: absolute;
    top: 32px;
    left: 444px;
}

.tutorial2-text{
    width: 330px;
    position: absolute;
    top: 100px;
    left: 320px;
}

.tutorial2-text::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.notification-item-tab{
    position: absolute;
    top: 32px;
    left: 550px;
}

.tutorial3-text{
    width: 330px;
    position: absolute;
    top: 100px;
    left: 460px;
}

.tutorial3-text::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.activity-item-tab{
    position: absolute;
    top: 32px;
    left: 810px;
}

.tutorial4-text{
    width: 330px;
    position: absolute;
    top: 100px;
    left: 700px;
}

.tutorial4-text::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.search-item-tab{
    position: absolute;
    top: 32px;
    left: 920px;
}

.tutorial5-text{
    width: 330px;
    position: absolute;
    top: 100px;
    left: 810px;
}

.tutorial5-text::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.folder-item-tab{
    position: absolute;
    top: 32px;
    left: 1030px;
}

.tutorial6-text{
    width: 330px;
    position: absolute;
    top: 100px;
    left: 950px;
}

.tutorial6-text::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}

.tutorial-user-header-dropdown{
    position: absolute;
    top: 110px;
    right: 22px;
}

.tutorial7-text{
    /* width: 330px; */
    position: absolute;
    top: 63px;
    right: 265px;
}

.tutorial7-text::after{
    content: "";
    position: absolute;
    right: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(90deg);
}

.tutorial8-text{
    width: 330px;
    position: absolute;
    top: 111px;
    right: 265px;
}

.tutorial8-text::after{
    content: "";
    position: absolute;
    right: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(90deg);
}

.tutorial9-text{
    /* width: 330px; */
    position: absolute;
    top: 176px;
    right: 265px;
}

.tutorial9-text::after{
    content: "";
    position: absolute;
    right: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(90deg);
}

.tutorial10-text{
    width: 330px;
    position: absolute;
    left: 480px;
    top: 355px;
}

.tutorial10-text::before{
    content: "";
    position: absolute;
    left: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(-90deg);
}

.tutorial11-text{
    width: 330px;
    position: absolute;
    left: 850px;
    top: 355px;
}

.tutorial11-text::before{
    content: "";
    position: absolute;
    left: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(-90deg);
}

.tutorial12-text{
    width: 330px;
    position: absolute;
    left: 1220px;
    top: 355px;
}

.tutorial12-text::before{
    content: "";
    position: absolute;
    left: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(-90deg);
}

.tutorial13-text{
    width: 330px;
    position: absolute;
    left: 880px;
    top: 355px;
}

.tutorial13-text::before{
    content: "";
    position: absolute;
    right: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(90deg);
}

.tutorial14-text{
    width: 330px;
    position: absolute;
    /* left: 480px; */
    top: 355px;
}

.tutorial14-text::before{
    content: "";
    position: absolute;
    /* left: -20px; */
    bottom: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    transform: rotate(180deg);
}

.tutorial-visible{
    visibility: visible;
}

.tutorial-invisible{
    visibility: hidden;
}

.show-tutorial{
    z-index: 101;
    visibility: visible;
}

.tutorial-board-grid{
    margin-top: 355px;
}

.mobile-tut-new-project{
    position: relative;
    left: 3px;
    top: 0px;
}
.tutorial10-temp-text{
    left: 106%;
    top: 10%;
}
.tutorial11-temp-text{
    left: 106%;
    top: 10%;
}
.tutorial12-temp-text{
    left: 106%;
    top: 10%;
}
.tutorial13-temp-text{
    left: 106%;
    top: 10%;
}
.tutorial13-text::before{
    left: -18px;
    right: auto;
    transform: rotate(270deg);
}
.tutorial14-temp-text{
    left: -104%;
    top: 7%;
}
.tutorial14-text::before{
    bottom: auto;
    left: auto;
    right: -20px;
    transform: rotate(90deg);
}
@media(max-width: 1182px){
    .tutorial14-temp-text{
        left: auto;
        top: auto;
        bottom: 106%;
    }
    .tutorial14-text::before{
        bottom: -20px;
        left: auto;
        right: auto;
        transform: rotate(180deg);
    }
}
@media (min-width: 1925px){
    .tutorial14-temp-text{
        left: -103%;
        top: 7%;
    }
    .tutorial14-text::before{
        bottom: auto;
        left: auto;
        right: -20px;
        transform: rotate(90deg);
    }
}
@media(max-width: 1184px){
    .tutorial11-temp-text{
        left: -103%;
    }
    .tutorial11-text::before{
        left: auto;
        right: -18px;
        transform: rotate(90deg);
    }
}
@media(max-width: 750px){
    .tutorial-text{
        padding: 16px 26px;
    }
    .tutorial10-temp-text{
        left: auto;
        top: auto;
        bottom: -80%;
        font-size: 12px;
        max-width: 70vw;
    }
    .tutorial10-text::before{
        left: auto;
        top: -20px;
        transform: rotate(360deg);
    }
    .tutorial11-temp-text{
        left: auto;
        top: auto;
        bottom: -77%;
        font-size: 12px;
        max-width: 70vw;
    }
    .tutorial11-text::before{
        right: auto;
        top: -20px;
        transform: rotate(360deg);
    }
    .tutorial12-temp-text{
        left: auto;
        top: auto;
        bottom: -73%;
        font-size: 12px;
        max-width: 70vw;
    }
    .tutorial12-text::before{
        left: auto;
        top: -20px;
        transform: rotate(360deg);
    }
    .tutorial13-temp-text{
        left: auto;
        top: auto;
        bottom: -83%;
        font-size: 12px;
        max-width: 70vw;
    }
    .tutorial13-text::before{
        left: auto;
        top: -20px;
        transform: rotate(-360deg);
    }
    .tutorial14-temp-text{
        font-size: 12px;
        max-width: 70vw;
    }
}
@media(max-width: 1940px) and (min-width: 1553px), (max-width: 1182px) and (min-width: 751px){
    .tutorial13-temp-text{
        left: -103%;
    }
    .tutorial13-text::before{
        left: auto;
        right: -20px;
        transform: rotate(90deg);
    }
}
@media (max-width : 1554px) and (min-width: 1184px){
    .tutorial12-temp-text{
        left: -103%;
        top: 10%;
    }
    .tutorial12-text::before{
        right: -18px !important;
        left: auto;
        transform: rotate(90deg);
    }
}
@media (max-width : 873px) {
    .mobile-tut-new-project{
        top: 10px;
    }
}
@media (max-width : 634px) {
    .mobile-tut-new-project{
        top: 27px;
    }
}
@media (max-width : 850px) {
    .mobile-tut-new-project{
        left: 30px;
    }
}
.dropdown-mobile{
    position: absolute;
    left: 31px;
    top: 74px;
}
.tutorial-mobile{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.tutorial-pointer-mobile{
    border-left: 10px solid transparent;
    border-right: 20px solid #226DDE;
    border-bottom: 10px solid transparent;
}
.tutorial-navbar-item-mobile{
    width: 165px;
    display: flex;
    align-items: center;
}
.tut-mobile-notification{
    margin-top: -11px;
}
.tut-mobile-activity{
    margin-top: -17px;
}
.tut-mobile-search{
    margin-top: -20px;
}
.tut-mobile-project-status{
    margin-top: -11px;
}
@media (max-width : 850px) {
    .dropdown-mobile{
        left: 16px;
    }
}
@media (max-width : 700px) {
    .tutorial-mobile{
        flex-direction: column;
    }
    .tutorial-pointer-mobile{
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #226DDE;
    }
}
.tutorial-user-mobile{
    display: flex;
    flex-direction: column;
}
.user-header-pointer-mobile{
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid  #226DDE;
    position: relative;
    top: -39px;
    display: none;
}
@media (max-width : 620px) {
    .user-header-pointer-mobile{
        display: block;
    }
    .tutorial7-text{
        right: 35px;
    top: 180px;
    width: 330px;
}
.tutorial7-text::after{
    display: none;
}
.tutorial8-text{
    right: 35px;
    top: 235px;
    width: 330px;
}
.tutorial8-text::after{
    display: none;
}
.tutorial9-text{
    right: 35px;
    top: 290px;
    width: 330px;
    }
    .tutorial9-text::after{
        display: none;
    }
}
.review-tutorial{
    position: absolute;
    top: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
}
.review-btn{
    top: 40px;
    padding-right: 30px;
}
.review-tut1-text{
    top: 110px;
}
.review-comment{
    position: absolute;
    top: 133px;
    left: 416px;
}
.review-tut-common{
    width: 330px;
    position: absolute;
    top: 183px;
    left: 257px;
}
.review-tut2::before{
        content: "";
        position: absolute;
        top: -20px;
        left: 160px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #226DDE;
}
.review-toggle{
    bottom: 98px;
    left: 810px;
}
.review-tut3{
    width: 330px;
    position: absolute;
    left: 923px;
    bottom: 26px;
}
.review-tut3-pointer{
    content: "";
    border-left: 10px solid transparent;
    border-right: 20px solid #226DDE;
    border-bottom: 10px solid transparent;
}
.review-tut3-pointer{
    position: absolute;
    left: -30px;
}
.review-tut3-text{
    width: 220px;
}
.review-edit{
    position: absolute;
    top: 132px;
    left: 460px;
}
.review-tut4{
    top: 183px;
    left: 296px;
}
.review-tut4::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}
.review-tut5{
    top: 192px;
    left: auto;
    right: 165px;
}
@media (max-width:1070px){
    .review-tut5{
        top: 293px;
    }
}
@media (max-width:465px){
    .review-tut5{
        top: 293px !important;
    }
}
.review-tut5::before{
    content: "";
    position: absolute;
    top: -20px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
}
.review-tut6{
    top: 192px;
    right: 90px;
    left: auto;
}
.review-tut6::before{
    content: "";
    position: absolute;
    top: -20px;
    left: auto;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #226DDE;
    right: 65px;
}
@media(max-width: 1070px){
    .review-tut6{
        top: 292px !important;
    }
}
@media(max-width: 465px){
    .review-tut6::before{
        left: auto !important;
        right: 20px;
    }
}
.review-tut-version{
    position: absolute;
    right: 255px;
    top: 123px;
}
.review-tut-approve{
    position: absolute;
    right: 92px;
    top: 123px;
}
@media (max-width : 1070px){
    .review-btn{
        top: 47px;
    padding-right: 30px;
    right: -19px;
    }
    .review-comment{
        top: 140px;
    left: 82px;
    }
}
@media (max-width : 850px) {
    .review-btn{
        top: 52px;
    }
}
@media (max-width : 400px) {
    .review-tut1-text{
        left: auto;
        font-size: 12px;
    max-width: 70vw;
    right: 20px;
    }
}
@media (max-width : 980px){
    .review-comment{
        top: 143px;
        left: 398px;
    }
    .review-edit{
        left: 447px;
        top: 142px;
        }
    .tutorial-text-heading-resp{
        left: -409px !important;
        top: 100px;
        position: absolute;
    }
    .tutorial-text-heading-resp2{
        left: -402px;
        position: absolute;
        min-width: 9rem;
        top: 99px;
    }
}
@media (max-width : 965px){
    .review-tut4::before{
        left: 65%;
    }
    .review-comment{
        top: 143px;
        left: 391px;
    }
    .review-tut-common{
        left: 245px;
    }
    .review-edit{
        left: 440px;
        }
        .tutorial-text-heading-resp{
            left: -402px !important;
        }
        .tutorial-text-heading-resp2{
            left: -396px;
        }
}
@media (max-width : 960px){
    .review-edit{
    left: 433px;
    }
    .review-comment{
        top: 143px;
    left: 389px;
    }
    .review-tut-common{
        left: 237px;
    }
    .tutorial-text-heading-resp{
        left: -393px !important;
    }
    .tutorial-text-heading-resp2{
        left: -396px;
    }
}
@media (max-width : 940px){
    .review-edit{
        left: 427px;
        }
    .review-comment{
        top: 143px;
    left: 379px;
    }
    .review-tut-common{
        left: 225px;
    }
    .tutorial-text-heading-resp{
        left: -386px !important;
    }
    .tutorial-text-heading-resp2{
        left: -384px;
    }
}
@media (max-width : 930px){
    .review-edit{
        left: 420px;
        }
        .tutorial-text-heading-resp{
            left: -383px !important;
        }
        .tutorial-text-heading-resp2{
            left: -378px;
        }
}
@media (max-width : 920px){
    .review-edit{
        left: 415px;
        }
    .review-comment{
        top: 143px;
    left: 370px;
    }
    .review-tut-common{
        left: 215px;
    }
    .tutorial-text-heading-resp{
        left: -380px !important;
    }
    .tutorial-text-heading-resp2{
        left: -373px;
    }
}
@media (max-width : 905px){
    .review-comment{
        left: 365px;
    }
    .review-edit{
        left: 410px;
        }
        .tutorial-text-heading-resp{
            left: -372px !important;
        }
        .tutorial-text-heading-resp2{
            left: -365px;
        }
}
@media (max-width : 895px){
    .review-edit{
        left: 400px;
        }
    .review-comment{
        top: 143px;
    left: 360px;
    }
    .review-tut-common{
        left: 198px;
    }
    .tutorial-text-heading-resp{
        left: -366px !important;
    }
    .tutorial-text-heading-resp2{
        left: -359px;
    }
}
@media (max-width : 882px){
    .review-comment{
        top: 143px;
        left: 355px;
    }
    .tutorial-text-heading-resp{
        left: -359px !important;
    }
    .tutorial-text-heading-resp2{
        left: -352px;
    }
}
@media (max-width : 875px){
    .review-comment{
        top: 143px;
        left: 350px;
    }
    .review-edit{
        left: 395px;
        }
        .tutorial-text-heading-resp{
            left: -356px !important;
        }
        .tutorial-text-heading-resp2{
            left: -349px;
        }
}
@media (max-width : 865px){
    .review-comment{
        top: 143px;
        left: 345px;
    }
    .review-edit{
        left: 390px;
        }
        .tutorial-text-heading-resp{
            left: -349px !important;
        }
        .tutorial-text-heading-resp2{
            left: -346px;
        }
}
@media (max-width : 860px){
    .review-comment{
    left: 340px;
    }
    .review-tut-common{
        left: 168px;
    }
    .tutorial-text-heading-resp{
        left: -348px !important;
    }
    .tutorial-text-heading-resp2{
        left: -340px;
    }
}
@media (max-width : 850px){
    .review-comment{
        top: 148px;
    }
    .review-edit{
        left: 382px;
        top: 149px;
        }
        .tutorial-text-heading-resp{
            left: -344px !important;
            top: 105px;
        }
        .tutorial-text-heading-resp2{
            left: -337px;
            top: 106px;
        }
}
@media (max-width : 843px){
    .review-comment{
        top: 148px;
        left: 335px;
    }
    .review-edit{
        left: 376px;
        }
        .tutorial-text-heading-resp{
            left: -339px !important;
        }
        .tutorial-text-heading-resp2{
            left: -335px;
        }
}
@media (max-width : 830px){
    .review-comment{
        top: 148px;
    left: 330px;
    }
    .review-tut-common{
        left: 158px;
    }
    .review-edit{
        left: 368px;
        }
        .tutorial-text-heading-resp{
            left: -335px !important;
        }
        .tutorial-text-heading-resp2{
            left: -329px;
        }
}
@media (max-width : 820px){
    .review-comment{
        top: 148px;
        left: 325px;
    }
    .tutorial-text-heading-resp{
        left: -328px !important;
    }
    .tutorial-text-heading-resp2{
        left: -321px;
    }
}
@media (max-width : 815px){
    .review-comment{
        left: 317px;
        }
        .review-edit{
            left: 368px;
            }
            .tutorial-text-heading-resp{
                left: -326px !important;
            }
            .tutorial-text-heading-resp2{
                left: -318px;
            }
}
@media (max-width : 800px){
    .review-comment{
    left: 310px;
    }
    .review-tut-common{
        left: 148px;
    }
    .review-edit{
        left: 354px;
        }
        .tutorial-text-heading-resp{
            left: -318px !important;
        }
        .tutorial-text-heading-resp2{
            left: -312px;
        }
}
@media (max-width : 783px){
    .review-comment{
        left: 305px;
    }
    .review-edit{
        left: 350px;
        }
        .tutorial-text-heading-resp{
            left: -308px !important;
        }
        .tutorial-text-heading-resp2{
            left: -304px;
        }
}
@media (max-width : 775px){
    .review-comment{
        left: 300px;
    }
    .review-edit{
        left: 345px;
        }
        .tutorial-text-heading-resp{
            left: -305px !important;
        }
        .tutorial-text-heading-resp2{
            left: -300px;
        }
}
@media (max-width : 765px){
    .review-comment{
    left: 290px;
    }
    .review-tut-common{
        left: 128px;
    }
    .review-edit{
        left: 338px;
        }
        .tutorial-text-heading-resp{
            left: -300px !important;
        }
        .tutorial-text-heading-resp2{
            left: -294px;
        }
}
@media (max-width : 750px)
{
    .review-comment{
        left: 285px;
    }
    .review-edit{
        left: 330px;
        }
        .tutorial-text-heading-resp{
            left: -293px !important;
        }
        .tutorial-text-heading-resp2{
            left: -288px;
        }
}
@media (max-width : 740px){
    .review-comment{
        left: 280px;
        }
        .review-edit{
            left: 325px;
            }
            .tutorial-text-heading-resp{
                left: -287px !important;
            }
            .tutorial-text-heading-resp2{
                left: -283px;
            }
}
@media (max-width : 730px){
    .review-comment{
        left: 275px;
        }
        .review-edit{
            left: 320px;
            }
            .tutorial-text-heading-resp{
                left: -283px !important;
            }
            .tutorial-text-heading-resp2{
                left: -281px;
            }
}
@media (max-width : 720px){
    .review-comment{
        top: 150px;
    left: 270px;
    }
    .review-tut-common{
        left: 108px;
    }
    .review-edit{
        left: 315px;
        }
        .tutorial-text-heading-resp{
            left: -280px !important;
        }
        .tutorial-text-heading-resp2{
            left: -271px;
        }
}
@media (max-width : 710px){
    .review-comment{
        top: 150px;
        left: 267px;
    }
    .review-edit{
        left: 310px;
        }
        .tutorial-text-heading-resp{
            left: -275px !important;
        }
        .tutorial-text-heading-resp2{
            left: -267px;
        }
}
@media (max-width : 700px){
    .review-comment{
        top: 150px;
        left: 265px;
    }
    .review-edit{
        left: 305px;
        }
        .tutorial-text-heading-resp{
            left: -267px !important;
        }
        .tutorial-text-heading-resp2{
            left: -261px;
        }
}
@media (max-width : 695px){
    .review-comment{
        left: 263px;
    }
    .review-edit{
        left: 302px;
        }
        .tutorial-text-heading-resp{
            left: -265px !important;
        }
        .tutorial-text-heading-resp2{
            left: -259px;
        }
}
@media (max-width : 690px){
    .review-comment{
        left: 260px;
    }
    .review-edit{
        left: 300px;
        }
        .tutorial-text-heading-resp{
            left: -263px !important;
        }
        .tutorial-text-heading-resp2{
            left: -258px;
        }
}
@media (max-width : 685px){
    .review-comment{
        left: 255px;
    }
    .review-edit{
        left: 297px;
        }
        .tutorial-text-heading-resp{
            left: -264px !important;
        }
        .tutorial-text-heading-resp2{
            left: -257px;
        }
}
@media (max-width : 680px){
    .review-comment{
    left: 250px;
    }
    .review-tut-common{
        left: 90px;
    }
    .review-edit{
        left: 295px;
        }
        .tutorial-text-heading-resp{
            left: -256px !important;
        }
        .tutorial-text-heading-resp2{
            left: -252px;
        }
}
@media (max-width : 670px){
    .review-comment{
        left: 247px;
    }
    .review-edit{
        left: 290px;
        }
        .tutorial-text-heading-resp{
            left: -254px !important;
        }
        .tutorial-text-heading-resp2{
            left: -247px;
        }
}
@media (max-width : 660px){
    .review-comment{
        left: 245px;
    }
    .review-edit{
        left: 287px;
        }
        .tutorial-text-heading-resp{
            left: -249px !important;
        }
        .tutorial-text-heading-resp2{
            left: -246px;
        }
}
@media (max-width : 650px){
    .review-comment{
    left: 240px;
    }
    .review-tut-common{
        left: 70px;
    }
    .review-edit{
        left: 280px;
        }
        .tutorial-text-heading-resp{
            left: -242px !important;
        }
        .tutorial-text-heading-resp2{
            left: -237px;
        }
}
@media (max-width : 640px){
    .review-comment{
        left: 230px;
    }
    .review-edit{
        left: 275px;
        }
        .tutorial-text-heading-resp{
            left: -239px !important;
        }
        .tutorial-text-heading-resp2{
            left: -232px;
        }
}
@media (max-width : 625px){
    .review-comment{
        left: 225px;
    }
    .review-edit{
        left: 267px;
        }
        .tutorial-text-heading-resp{
            left: -230px !important;
        }
        .tutorial-text-heading-resp2{
            left: -224px;
        }
}
@media (max-width : 615px){
    .review-comment{
    left: 220px;
    }
    .review-tut-common{
        left: 50px;
    }
    .review-edit{
        left: 262px;
        }
        .tutorial-text-heading-resp{
            left: -225px !important;
        }
        .tutorial-text-heading-resp2{
            left: -220px;
        }
}
@media (max-width : 600px){
    .review-comment{
        left: 210px;
    }
    .review-edit{
        left: 255px;
        }
        .tutorial-text-heading-resp{
            left: -218px !important;
        }
        .tutorial-text-heading-resp2{
            left: -213px;
        }
}
@media (max-width : 585px){
    .review-comment{
        left: 205px;
    }
    .review-edit{
        left: 248px;
        }
        .tutorial-text-heading-resp{
            left: -211px !important;
        }
        .tutorial-text-heading-resp2{
            left: -204px;
        }
}
@media (max-width : 575px){
    .review-comment{
    left: 200px;
    }
    .review-tut-common{
        left: 40px;
    }
    .review-edit{
        left: 240px;
        }
        .tutorial-text-heading-resp{
            left: -206px !important;
        }
        .tutorial-text-heading-resp2{
            left: -200px;
        }
}
@media (max-width : 565px){
    .review-comment{
        left: 195px;
    }
    .review-edit{
        left: 240px;
        }
        .tutorial-text-heading-resp{
            left: -200px !important;
        }
        .tutorial-text-heading-resp2{
            left: -195px;
        }
}
@media (max-width : 550px){
    .review-comment{
    left: 190px;
    }
    .review-tut-common{
        left: 20px;
    }
    .review-edit{
        left: 230px;
        }
        .tutorial-text-heading-resp{
            left: -193px !important;
        }
        .tutorial-text-heading-resp2{
            left: -189px;
        }
}
@media (max-width : 540px){
    .review-comment{
        left: 185px;
    }
    .review-edit{
        left: 225px;
        }
        .tutorial-text-heading-resp{
            left: -187px !important;
        }
        .tutorial-text-heading-resp2{
            left: -182px;
        }
}
@media (max-width : 530px){
    .review-comment{
        left: 178px;
    }
    .review-edit{
        left: 220px;
        }
        .tutorial-text-heading-resp{
            left: -184px !important;
        }
        .tutorial-text-heading-resp2{
            left: -178px;
        }
}
@media (max-width : 520px){
    .review-comment{
        left: 174px;
    }
    .review-edit{
        left: 217px;
        }
        .tutorial-text-heading-resp{
            left: -178px !important;
        }
        .tutorial-text-heading-resp2{
            left: -172px;
        }
}
@media (max-width : 510px){
    .review-comment{
    left: 170px;
    }
    .review-tut-common{
        left: 10px;
    }
    .review-edit{
        left: 210px;
        }
        .tutorial-text-heading-resp{
            left: -174px !important;
        }
        .tutorial-text-heading-resp2{
            left: -167px;
        }
}
@media (max-width : 500px){
    .review-comment{
        left: 165px;
    }
    .review-edit{
        left: 207px;
        }
        .tutorial-text-heading-resp{
            left: -169px !important;
        }
        .tutorial-text-heading-resp2{
            left: -163px;
        }
}
@media (max-width : 490px){
    .review-comment{
        left: 160px;
    }
    .review-edit{
        left: 200px;
        }
        .tutorial-text-heading-resp{
            left: -164px !important;
        }
        .tutorial-text-heading-resp2{
            left: -158px;
        }
}
@media (max-width : 480px){
    .review-comment{
        left: 155px;
    }
    .review-edit{
        left: 194px;
        }
        .tutorial-text-heading-resp{
            left: -158px !important;
        }
        .tutorial-text-heading-resp2{
            left: -153px;
        }
}
@media (max-width : 470px){
    .review-comment{
        left: 150px;
    }
    .review-edit{
        left: 190px;
        }
        .tutorial-text-heading-resp{
            left: -153px !important;
        }
        .tutorial-text-heading-resp2{
            left: -149px;
        }
}
@media (max-width : 465px){
    .review-tut4::before{
        left: 45% !important;
    }
    .review-comment{
        left: 140px;
        }
    .review-tut-common::before{
        left: 100px;
    }
    .review-tut-common{
        left: 30px;
        width: 70vw;
        top: 195px;
    }
    .review-edit{
        left: 187px;
        }
        .tutorial-text-heading-resp{
            left: -150px !important;
        }
        .tutorial-text-heading-resp2{
            left: -145px;
        }
}
@media (max-width : 445px){
    .review-comment{
        left: 137px;
    }
    .review-edit{
        left: 176px;
        }
        .tutorial-text-heading-resp{
            left: -142px !important;
            min-width: 9.3rem;
        }
        .tutorial-text-heading-resp2{
            left: -135px;
            min-width: 9.1rem;
        }
}
@media (max-width : 440px){
    .review-comment{
        left: 130px;
    }
    .review-edit{
        left: 174px;
        }
        .tutorial-text-heading-resp{
            left: -140px !important;
        }
        .tutorial-text-heading-resp2{
            left: -129px;
        }
}
@media (max-width : 425px){
    .review-comment{
        left: 125px;
    }
    .review-edit{
        left: 166px;
        }
        .tutorial-text-heading-resp{
            left: -132px !important;
        }
        .tutorial-text-heading-resp2{
            left: -127px;
        }
}
@media (max-width : 418px){
    .review-comment{
        left: 122px;
    }
    .review-edit{
        left: 164px;
        }
        .tutorial-text-heading-resp{
            left: -126px !important;
    min-width: 9.1rem;
        }
        .tutorial-text-heading-resp2{
            left: -122px;
            min-width: 9rem;
        }
}
@media (max-width : 410px){
    .review-comment{
        left: 120px;
        }
    .review-tut-common::before{
        left: 85px;
    }
    .review-tut-common{
        left: 30px;
        width: 70vw;
         top: 195px;
    }
    .review-edit{
        left: 160px;
        }
        .tutorial-text-heading-resp{
            left: -119px !important;
        }
        .tutorial-text-heading-resp2{
            left: -118px;
            min-width: 8.7rem;
        }
}
@media (max-width : 405px){
    .review-comment{
        left: 116px;
    }
    .review-edit{
        left: 157px;
        }
        .tutorial-text-heading-resp{
            left: -103px !important;
            
        }
        .tutorial-text-heading-resp2{
            left: -116px;
        }
}
@media (max-width : 395px){
    .review-comment{
        left: 110px;
    }
    .review-edit{
        left: 153px;
        }
        .tutorial-text-heading-resp{
            left: -102px !important;
            min-width: 8.3rem;
        }
        .tutorial-text-heading-resp2{
            left: -110px;
            min-width: 8.2rem;
        }
}
@media (max-width : 385px){
    .review-comment{
        left: 105px;
    }
    .review-edit{
        left: 146px;
        }
        .tutorial-text-heading-resp{
            left: -93px !important;
        }
        .tutorial-text-heading-resp2{
            left: -108px;
        }
}
@media (max-width : 375px){
    .review-comment{
        left: 100px;
        }
    .review-tut-common::before{
        left: 80px;
    }
    .review-tut-common{
        left: 20px;
        width: 70vw;
        top: 195px;
    }
    .review-edit{
        left: 143px;
        }
        .tutorial-text-heading-resp{
            left: -84px !important;
            min-width: 7.9rem;
        }
        .tutorial-text-heading-resp2{
            left: -101px;
            min-width: 7.6rem;
        }
}
@media (min-width : 2605px)
{
    .review-toggle{
        left: 54% !important;
    }
    .review-tut3{
        left: 58%;
    }
}
@media (max-width : 2604px)
{
    .review-toggle{
        left: 54% !important;
    }
    .review-tut3{
        left: 58.5%;
    }
}
@media (max-width : 2400px)
{
    .review-toggle{
        left: 54% !important;
    }
    .review-tut3{
        left: 59%;
    }
}
@media (max-width : 2250px)
{
    .review-toggle{
        left: 56% !important;
    }
    .review-tut3{
        left: 61%;
    }
}
@media (max-width : 1910px)
{
    .review-toggle{
        left: 57% !important;
    }
    .review-tut3{
        left: 63%;
    }
}
@media (max-width : 1660px)
{
    .review-toggle{
        left: 58% !important;
    }
    .review-tut3{
        left: 65%;
    }
}
@media (max-width : 1530px)
{
    .review-toggle{
        left: 59% !important;
    }
    .review-tut3{
        left: 67%;
        font-size: 12px;
        width: 15rem;
        padding: 10px 18px;
        bottom: 47px;
    }
}
@media (max-width : 1360px)
{
    .review-toggle{
        left: 60% !important;
    }
    .review-tut3{
        left: 68.5%;
    }
}
@media (max-width : 1250px)
{
    .review-toggle{
        left: 61% !important;
    }
    .review-tut3{
        left: 70%;
    }
}
@media (max-width : 1145px)
{
    .review-toggle{
        left: 64% !important;
    }
    .review-tut3{
        left: 72%;
    }
}
@media (max-width : 1070px)
{
    .review-tut3{
        left: 59%;
    }
}
@media (max-width : 1070px)
{
    .review-toggle{
        left: 48% !important;
        bottom: -3px !important;
    }
    .review-tut3{
        bottom: -56px;
        left: 59%;
    }
}
@media (max-width : 735px)
{
    .review-tut3{
        left: 63%;
    }
}
@media (max-width : 650px)
{
    .review-tut3-pointer{
        left: 20%;
        bottom: -10px;
    }
    .review-tut3{
        bottom: 50px;
        left: 40%;
    }
}
@media (max-width : 445px)
{
    .review-tut3-pointer{
        left: 40%;
        bottom: -10px;
    }
    .review-tut3{
        bottom: 50px;
        left: 30%;
    }
}
@media (max-width : 375px)
{
    .review-tut3-pointer{
        left: 50%;
        bottom: -10px;
    }
    .review-tut3{
        bottom: 50px;
        left: 20%;
    }
}
