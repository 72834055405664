*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --PhoneInputCountrySelectArrow-borderWidth: 1.5px !important;
  --PhoneInputCountrySelectArrow-opacity: 1 !important;
  --PhoneInputCountrySelectArrow-color: white !important;
  --PhoneInputCountrySelectArrow-color--focus: #ffffff !important;
  --screen-lg:1100px;
  --screen-md:850px;
  --screen-sm:600px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

@font-face {
  font-family: avenirLight;
  src: url(../fonts/AvenirLTProLight.otf);
}
@font-face {
  font-family: avenirMedium;
  src: url(../fonts/AvenirLTProMedium.otf);
}
@font-face {
  font-family: gilroyRegular;
  src: url(../fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: gilroySemiBold;
  src: url(../fonts/Gilroy-SemiBold.ttf);
}
@font-face {
  font-family: gilroyMedium;
  src: url(../fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: gilroyThin;
  src: url(../fonts/Gilroy-Thin.ttf);
}
@font-face {
  font-family: gilroyBold;
  src: url(../fonts/Gilroy-Bold.ttf);
}
@font-face {
  font-family: montserrat;
  src: url(../fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: inter;
  src: url(../fonts/Inter-Regular.ttf);
}
body {
  background: #333333;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
