
.upload-field {
    width: 740px;
    margin-left: 40px;
    margin-top: 20px;
}

.files-box {
    background-color: #4B4B4B;
}

.files-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
}

.file-box{
    position: relative;
}
.file-remove{
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
}
.file-item {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    min-width: 150px;
}

.file-item img {
    width: 40px;
    height: 40px;
}

.file-item-description {
    font-size: 15px;
    display: grid;
    gap: 2px;
    font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */
word-break: break-all;
letter-spacing: 0.02em;

/* White max */

color: #FFFFFF;
}
.document-link{
 display: flex;
 align-items: center;
 justify-content: space-between;
 background: rgba(255, 255, 255, 0.12);
border-radius: 4px;
padding: 10px 15px;
margin-bottom: 20px;
}
.document-link a{
 text-decoration: none;
 font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

letter-spacing: 0.02em;
color: #0085FF;
font-family: gilroySemiBold;
display: flex;
align-items: center;
gap: 12px;
}
.document-link-copy{
    background: #707171;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    position: relative;
}
.document-link-copy img{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.file-type {
    color: rgba(214, 214, 214, 1);
}
.upload-files-input {
    display: none;
}

.upload-files-btn {
    display: flex;
    align-items: left;
    gap: 10px;
    align-items: center;
    width: 725px;
}

.add-description{
    display: flex;
    align-items: center;
    gap: 10px;
}
.circle-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    cursor: pointer;
}

.dropbox-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.upload-text{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.upload-text span{
    font-weight: 500;
font-size: 12px;
line-height: 18px;
margin-left: 10px;
letter-spacing: 0.02em;

/* Primary solid */

color: #01D9AB;
}
.dashboard-project-header{
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 position: relative;
 place-items: center;
}
.dashboard-header-secondary-section{
    display: flex;
    justify-content: end;
    position: absolute;
    right: 30px;
    top: 40px;
}
.dashboard-project-status-container{
    display: flex;
    justify-content: center;
    position: relative;
}
.dashboard-project-status{
    border: 2px solid #FFFFFF;
border-radius: 4px;
padding: 8px 20px;
font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;
color: white;
text-transform: capitalize;
}
.dashboard-project-header-content{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.dashboard-project-content-1{
    font-weight: 600;
    font-size: 34px;
    line-height: 38px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
}
.dashboard-project-content-2{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: avenirMedium;
    text-align: center;
    color: #FFFFFF;
}
.dashboard-project-content-3{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: gilroySemiBold;
    color: #D6D6D6;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.project-involved-modal{
    max-width: 600px;
}
.project-involved{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.extra-people-count{
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: #fff;
}
.project-user-photo-grid{
    display: flex;
}
.project-user-photo-grid-profile{
    height: 28px;
    width: 28px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid white;
}
.project-user-photo-grid-profile:not(:last-child){
    margin-right: -5px;
    
}
.project-user-photo-grid-profile-default{
    background-color: #BA68C8;
    height: 100%;
    width: 100%;
    text-transform: capitalize;
    letter-spacing: -1.5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}
.project-user-photo-grid-profile img{
    height: 100%;
    width: 100%;
}
.board-grid{
    margin-top: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap:30px;
    padding-bottom: 90px;
}

.project-board{
    text-decoration: none;
    background: #4B4B4B;
    border: 2px solid #4B4B4B;
    border-radius: 12px;
    width: 341px;
    height: 253px;
    position: relative;
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;   
}
.board-count{
    position: absolute;
    right: -12px;
    top: -12px;
    background: #01D9AB;
    border-radius: 55px;
    height: 27px;
    width: 27px;
    color: white;
    text-align: center;
}
.board-heading{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */
    
    letter-spacing: 0.02em;
    font-family: gilroySemiBold;
    color: #FFFFFF;
}
.board-icon{
    width: 69px;
    height: 69px;
    background: #707171;
    border-radius: 55px;
    position: relative;
}
.board-icon img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.board-description{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: gilroyMedium;
    text-align: center;
    letter-spacing: 0.02em;
    
    color: #9A9A9A;
    
}
.project-desc{
    width: 100%;
    display: flex;
    justify-content: center;
}
.project-desc-content{
    width: 30%;
}
.dashboard-project-content-3 p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    text-align: center;
}
@media screen and (max-width: 850px) {
    .upload-field {
        width: 320px;
        margin-left: 40px;
    }
    .files-list {
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .upload-files-btn {
        width: 100%;
    }
    .dashboard-project-header{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 20px;
        gap: 10px;
        position: relative;
    }
    .dashboard-project-header-back{
        margin-right: auto;
    }
    .dashboard-project-status{
        height: 44px;
        padding-top: 10px;
    }
    .dashboard-project-header-content{
        width: 100%;
    }
    .dashboard-project-content-1{
        line-height: 26px;
        letter-spacing: 0.02em;
    }
    .dashboard-project-content-2{
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    }
    .dashboard-project-content-3{
        flex-direction: column;
        margin-left: 10px;
    }
    .dashboard-project-header-back{
        margin-right: auto;
    }
    .project-involved{
        flex-direction: column;
    }
    .invite-btn{
        align-self: flex-end;
        margin-top: 24px;
    }
    .invite-btn-center{
        align-self: center;
    }
    .dashboard-header-secondary-section{
        position: relative;
        right: 0px;
        top: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 510px) {
    .board-title-sub{
        display: none;
    }
}