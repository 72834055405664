
.notification-box {
    width: 80%;
    font-family: avenirLight;
    font-weight: 500;
    background-color: #4B4B4B;
    margin: 20px auto;
    color: #fff;
    padding: 25px;
}

.admin-notification{
    width: 91.5%;
}

.notification {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    gap: 10px;
}
.person-img{
    width: 47px;
    height: 47px;
}
.person-img img{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    object-fit: cover;
}
.person-img-default {
    font-family: gilroyMedium;
    background-color: #BA68C8;
    border-radius: 100%;
    width: 47px;
    height:47px;
    font-size: 24px;
    color: #fff;
    display: flex;
    justify-content: center;
    letter-spacing: -2px;
    align-items: center;
    text-transform: uppercase;
}


.notification-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    font-family: avenirLight ;
}

.notification-line {
    display: flex;
    align-items: center;
}

.person-name {
    font-size: 18px;
    text-transform: capitalize;
}

.place {
    font-size: 18px;
    color: #27DC65;
    text-transform: capitalize;
}

.notification-link {
    color: #40BAFF;
    font-size: 13px;
    text-decoration: none;
}

.notification-info{
    font-size: 13px;
    margin-top: 2px;
    color: rgba(214, 214, 214, 1);
}

.notification-time {
    font-size: 14px;
    color: #fff;
    text-align: right;
    color: rgba(214, 214, 214, 1);
}
.notification-day{
    font-weight: 500;
font-size: 13px;
line-height: 15px;
font-family: avenirMedium;
color: #FFFFFF;

}

@media (max-width: 1000px){
    .notification-day{
        font-size: 11px;
    }
    .notification-time{
        font-size: 13px;
        line-height: 15px;
    }
}
.tag {
    background: #27DC65;
    margin: 0px;
    margin-left: 10px;
}

.no-notification {
    display: grid;
    justify-items: center;
}

.no-notification-content {
    background-color: #4B4B4B;
    color: #fff;
    text-align: center;
    padding: 25px;
    width: 55%;
    margin: 40px;
}

.no-notification .heading {
    font-size: 20px;
    margin-bottom: 10px;
}

@media (max-width: 850px) {
    .notifications-title{
        width: 100%;
        position: absolute;
        top: -30px;
    }
    .notifications-search-form{
        width: 100%;
        position: absolute;
        left: 2px;
        top: 50px;
    }
    .notification-box {
        padding: 10px;
        width: 100%;
    }
    .notification {
        align-items: flex-start;
    }
    .person-img {
        width: 40px;
        height: 40px;
        font-size: 20px;
        
    }
    .person-img-default{
        width: 40px;
        height: 40px;
    }
    .notification-content {
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
    }
    .tag {
        display: none;
    }
    .no-notification-content {
        margin: 10px;
        margin-bottom: 40px;
        width: 95%;
    }
    .no-notification {
        margin: 5px;
    }
    .notification-time{
        text-align: left;
    }
}