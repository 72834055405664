
.activity-board-header{
    font-weight: 600;
    font-size: 34px;
    line-height: 38px;
    /* identical to box height, or 112% */
    
    letter-spacing: 0.02em;
    text-transform: capitalize;
   
    /* White max */
    font-family: gilroySemiBold;
    color: #FFFFFF;
    text-align: center;
}
.activity-blocks{
    background: #4B4B4B;
    margin: 20px 0;
    padding: 10px 0 20px 0;
}
.activity-block{
    display: flex;
    flex-direction: column;
}
.activity-block-right{
    align-items: end;
}
.activity-list{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
   width: 50%;
}

.activity-list-left{
    border-right: 1px solid #D6D6D6;
   
}
.activity-list-right{
    border-left: 1px solid #D6D6D6;
  
}
.activity-content{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.activity-item{
  display: flex;
  gap: 10px;
  
}
.activity-item:not(:last-child){
    margin-bottom: 15px;
}
.activity-date{
    width: 100%;
  padding: 10px 0;
   text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    
    /* White max */
    
    color: #FFFFFF;
    
}
.activity-item-header{
 display: flex;
 justify-content: space-between;
 align-items: baseline;
}
.activity-item-profile{
 display: flex;
 align-items: baseline;
 margin-bottom: 5px;
 gap: 5px;
}
.activity-item-name{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    
    /* White max */
    
    color: #FFFFFF;
    
}
.activity-item-role{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    
    color: #A2A2A2;
    
}
.activity-item-time{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    
    /* Grey */
    
    color: #D6D6D6;
}
.activity-text{
    font-weight: 500;
font-size: 18px;
line-height: 24px;
/* or 133% */
font-family: avenirMedium;

/* Grey */

color: #D6D6D6;
}
.activity-image{
    /* max-height: 250px; */
    margin: 10px 0;
    width: 100%;
}
@media (max-width: 760px) {
    .activity-item-header{
        flex-direction: column;
    }
}
@media (max-width: 580px) {
    .activity-blocks{
        width: 560px;
    }
}
@media (max-width: 630px) {
    .activity-item-name{
        font-size: 18px;
    }
    .activity-text{
        font-size: 15px;
    }
}

.dialogue-box-activity{
    background-color: #4B4B4B;
    padding: 20px;
    margin-top: 25px;
    border-radius: 4px;
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}
.dialogue-box-activity-heading{
    color: #fff;
font-family: "gilroySemiBold";
text-align: center;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 118.182% */
letter-spacing: 0.44px;
margin-bottom: 16px;
}
.dialogue-box-activity-text{
    color: #D6D6D6;
text-align: center;
font-family:"gilroySemiBold";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
}
@media (max-width : 600px) {
    .activity-blocks{
        width: fit-content;
    }
    .activity-list{
        width: 100%;
    }
    .activity-list-left{
        border-right: none;
    }
}
.activity-doc-image{
    margin: 20px 0;
    text-decoration: none;
}
.activity-doc-image > img{
    width: 150px;
}
.activity-doc-image > p{
    color: white;
    margin-top: 20px;
    font-size: 16px;
}