.invoice{
    width: 868px;
    margin: 40px auto;
    background: #ffffff;
    box-shadow: 0 1px 25px rgba(0,0,0,.05);
}

.invoice-header{
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
}

.invoice-header img{
    margin-left: 81px;
    width: 232px;
    height: 89px;
}

.invoice-heading{
    padding-bottom: 22px;
    border-bottom: 16px solid #0085FF;
}

.invoice-heading-text{
    width: 225px;
    height: 32px;
    font-family: gilroySemiBold;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #4B4B4B;
    margin-right: 69px;
    margin-top: 28px;
}

.invoice-body-header{
    margin-left: 46px;
    margin-top: 43px;
}

.invoice-body{
    font-family: gilroyRegular;
}

.invoice-to{
    font-family: gilroyRegular;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #0084FF;
    display: flex;
    gap: 5px;
}

.field-title{
    font-weight: 700;
    font-size: 18px;
}

.invoice-number{
    display: flex;
    gap: 12px;
    margin: 6px 0;
}

.invoice-date{
    display: flex;
    gap: 37px;
    margin: 6px 0;
}

.invoice-table{
    margin-left: 8px;
    margin-top: 68px;
    width: 783px;
    border-left: 38px solid #0085FF;
    font-family: montserrat;
}

.invoice-field{
    display: flex;
    padding: 0 42px;
    height: 60px;
    align-items: center;
}

.table-head{
    font-weight: 700;
    width: 250px;
}

.bg-blue{
    background: #D5DCFF;
}

.invoice-details{
    margin-left: 46px;
    margin-top: 37px;
    display: flex;
    justify-content: space-between;
    margin-right: 68px;
}

.invoice-details-header{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #0084FF;
}

.invoice-payment-details{
    text-align: right;
}

.details-field{
    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.details-field-head{
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.invoice-payment-details .details-field{
    justify-content: flex-end;
}

.terms-conditions{
    margin-left: 46px;
    margin-top: 32px;
    padding-bottom: 82px;
}

.terms-description{
    margin-top: 10px;
    width: 483px;
    height: 72px;
    font-family: inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}
