.auth-page{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    height: 100vh;
}

.auth-page .btn{
    width: 100%;
}
.auth-background{
    position: relative;
}
.auth-overlay{
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: linear-gradient(223.16deg, rgba(51, 51, 51,0.7) 0%, rgba(51, 51, 51, 0.75) 65.78%, rgba(51, 51, 51, 0.65) 98.32%);
   display: grid;
    justify-items: end;
}
.auth-content{
    display: grid;
    justify-items: end;
    align-content: center;
    gap: 0;
 bottom:50%;
 right: 100px;
 margin-right: 22px;
}
.auth-content img{
    width: 255px;
    height: 48px;
}
.auth-content-text{
font-family: gilroySemiBold;
    /* font-weight: 600; */
    font-size: 5vw;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-top: 15px;
}
.overlay-highlight{
    font-family: gilroyMedium;
    font-weight: 700;
    font-size: 21px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #01D9AB;
    text-align: end;
    margin-bottom: 15px;
}
.overlay-text{
    font-family: 'Roboto', sans-serif;
    text-align: end;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.auth-background .bg-img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
   animation: fadeIn 0.5s;
}
.form-bg{
    background: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.default-header{
    display: none;
    margin-top: 34px;
    margin-bottom: 52px;
}
@media screen and (max-width: 850px){
    .default-header{
        display: grid;
        justify-items: center;
        margin-top: 0px;
        margin-bottom: 35px;
    }
}
.bgLogo{
    width: 143px;
}
.members-portal{
    font-family: gilroySemiBold;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #D6D6D6;
    opacity: 0.7;
}
.auth-form {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
}
.auth-form-content{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-heading{
    /* width: 100%; */
    font-family: gilroySemiBold;
    margin-bottom: 12px;
    /* font-weight: 600; */
font-size: 34px;
line-height: 38px;
letter-spacing: 0.02em;
color: #FFFFFF;
}
.form-subheading{
    font-family: gilroySemiBold;
    align-self: flex-start;
    /* font-weight: 600; */
font-size: 16px;
line-height: 18px;
margin-bottom: 45px;
letter-spacing: 0.02em;
color: #D6D6D6;

opacity: 0.7;
}

.form-toggle{
 text-align: center;
 font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

letter-spacing: 0.02em;
}
.form-toggle span,.form-toggle a{
    font-family: gilroyMedium;
}

.highlight{
    text-decoration: none;
    color:#01D9AB;
    /* margin-left: 10px; */
    cursor: pointer;
    margin-top: 8px;
}

.auth-skip{
    cursor: pointer;
    position: absolute;
    z-index: 12;
    top: 50px;
    right: 100px;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
border-bottom: 1px solid white;
letter-spacing: 1px;
padding: 5px 0;
color: #FFFFFF;
}
.otp-input{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.otp-input input{
    margin-right: 0 !important;
    padding: 5px !important;
    background-color: #4B4B4B;
    color: #D6D6D6;
    outline: none;
    border: none;
    width: 43px !important;
    height: 41px !important;
}
.otp-input input:not(:last-child){
    margin-right: 20px;
}
.phone-box{
    position: relative;
 display: flex;
 align-items: center;
 background: #4B4B4B;
 padding-left: 10px;
 border-radius: 4px;
}
.phone-box .form-input{
    padding-left: 60px;
}
.phone-code{
    position: absolute;
    left: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
  color: #FFFFFF;
}
.phone-code span{
    font-weight: 600;
font-size: 16px;
line-height: 18px;
letter-spacing: 0.02em;
color: #FFFFFF;
}
.phone-code img{
    margin-left: 5px;
}
.password-box{
    position: relative;
 background: #4B4B4B;
 border-radius: 4px;
}
.password-box img{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.password-box .form-input{
    padding-right: 35px;
}
.forgot-undo{
    font-family: gilroyMedium;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
padding: 2px 0;
letter-spacing: 0.02em;;
    color: #01D9AB;
    border-bottom: 1px solid #01D9AB;
    align-self: flex-end;
    cursor: pointer;
    margin-top: 12px;
}
.input-error{
  display: flex;
  align-items: center;   
  margin-top: 5px;
}
.input-error span{
    font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */

letter-spacing: 0.02em;

color: #FF3333;
}
.input-error img{
    margin-right: 5px;
}
@media (max-width:1100px){
.auth-page{
    grid-template-columns: 1fr 1fr;
}
.auth-form-content{
    width: 100%;
}
}
@media (max-width:900px){

 .auth-content{
   
    right: 50px;
    
   }
}
@media (max-width:800px){
    .auth-page{
        grid-template-columns: 1fr;
    }
    .auth-background{
        display: none;
    }
    .form-bg{
        padding: 30px;
    }
    .highlight, .forgot-undo{
        margin-top: 6px;
    }
}
@media (max-width:500px){
    .auth-form{
        width: 100%;
        padding: 0 15px;
    }
    .form-heading{
        font-size: 24px;
        line-height: 26px;
    }
    .form-subheading{
        font-size: 14px;
        line-height: 16px;
    }
    .form-toggle{
        font-weight: 500;
font-size: 12px;
line-height: 18px;
    }
    .auth-skip{
        top: 32px;
        right: 15px;
    }
    .auth-skip-resp{
        position: static;
        font-size: 12px;
        line-height: 6px;
        font-family: gilroySemiBold;
        margin-top: 120px;
    }
    .form-bg{
        padding: 10px;
    }
}
.logo-link{
    display: flex;
    text-decoration: none;
    align-items: baseline;
}
.logo-cgi{
    position: relative;
    bottom: 7px;
    color: white;
}
@media (max-width : 800px) {
    .logo-cgi{
        font-size: 12px;
        bottom: 5px;
        left: 4px;
    }
}
.privacy-policy-text{
    margin-top: 15px;
}
.privacyModal{
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.privacyDiv{
    width: 70vw;
    height: 90vh;
    background-color: #4B4B4B;
    overflow: scroll;
}
@media (max-width : 610px) {
    .privacyDiv{
        width: 90vw;
    }
}
@media (max-width : 500px) {
    .privacyLogo > img{
        width: 250px !important;
    }
    .privacyLogo > p{
        font-size: 12px;
    }
}
.privacyCross{
    display: flex;
    justify-content: flex-end;
    font-size: 28px;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
}
.privacyCross > p{
    margin-right: 10px;
    margin-top : 10px;
    color: white;
    cursor: pointer;
}
.privacyContent{
    
    padding: 0 30px;
}
.privacyLogo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 3px solid #992629;
}
.privacyLogo > img{
    width:300px;
}
.privacyLogo > p{
    color: #fff;
}
.privacyHeading{
    text-transform: uppercase;
    color: #fb2227;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 22px;
}
.privacyPointHeading{
    color: #fb2227;
    font-weight: 600;
}
.privacySubHeading{
    color: #fff;
    margin-bottom: 30px;
}
.privacyPointText{
    margin-top: 10px;
    color: white;
}
.privacyPoint{
    margin-bottom: 20px;
}

@media (max-width: 800px){
    .otp-input{
        max-width: 20rem;
    }
    .otp-input input{
        height: 40px !important;
        width: 40px !important;
        margin-right: 0px !important;
    }
}
.highlight{
    font-family: gilroySemiBold !important;
}