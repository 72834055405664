.react-pdf__Page__textContent {
    display: none;
}
.react-pdf__Page__canvas{
    width: 100% !important;
    height: auto !important;
}
.react-pdf__Page{
    margin-bottom: 20px;
}
.react-pdf__Page__annotations {
    width: 100% !important;
    height: 100% !important;
}