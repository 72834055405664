.auto-search{
 width: 770px;
 height: 60vh;
overflow: auto;
padding: 0 40px;
}
.auto-search-input-bar{
   display: flex;
   align-items: end;
   width: 100%;
   gap: 40px;
   margin: 20px 0;
}
.auto-search-input{
  flex:1;
}
.auto-search-input-label{
    font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
margin-bottom: 5px;
color: #FFFFFF;

}
.auto-search-input input{
    padding: 15px;
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
border: 1.5px solid white;
font-weight: 600;
font-size: 16px;
line-height: 18px;
letter-spacing: 0.02em;
color: #D6D6D6;
text-transform: capitalize;
}
.auto-search-input input:focus{
    border: 1.5px solid #01D9AB;
}
.auto-search-results{
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #707171;
border-radius: 4px;
padding: 25px;
}
.auto-search-result{
  padding: 15px 25px;
  background: #707171;
 width: 100%;
 display: flex;
 align-items: center;
 gap: 15px;
 cursor: pointer;
}
.rdw-mention-link{
  background: transparent;
  color: #40BAFF;
}
.wysiwyg-mention{
  text-decoration: none;
  color: #40BAFF;
}
.mention{
  padding: 10px;
  background: #707171;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.todo-auto-search-result{
  padding: 6px 10px;
  background-color: #4B4B4B;
}
.auto-search-result:hover,.mention:hover{
  background: #7b7c7c;
}
.auto-search-result:not(:last-child){
    border-bottom: 0.4px solid #D6D6D6;
}
.search-profile,.mention-profile{
    font-family: gilroyMedium;
    background-color: #BA68C8;
    border-radius: 50%;
    width: 47px;
    height: 47px;
    font-size: 24px;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    text-transform: uppercase;
}
.search-name,.mention-name{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
/* identical to box height, or 118% */

letter-spacing: 0.02em;

/* White max */

color: #FFFFFF;

font-family: gilroySemiBold;
text-transform: capitalize;
}
.todo-search-profile,.mention-profile{
   height: 26px;
   width: 26px;
   font-size: 16px;
line-height: 19px;
}
.todo-search-name,.mention-name{
  font-size: 14px;
  line-height: 16px;
}
@media (max-width:850px){
  .auto-search{
    width: fit-content;
  }
}