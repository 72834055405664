.sidebar{
    background-color: #4B4B4B;
    height: 100%;
    padding: 20px 31px;
    width: 315px;
    position: fixed;
    z-index: 10;
}
.sidebar ul{
    display: flex;
    flex-direction: column;
}
.sidebar .navbar-item{
    margin-bottom: 9px;
}
.navbar-item{
    padding: 12px 16px 12px 16px;
}
.sidebar-title{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 5px;
    text-align: center;
}
.sidebar-logout{
    background-color: #8B000F;
}
.logo-container{
    display: flex;
    justify-content: center;
}
@media (max-width: 1000px){
    .sidebar{
        display: none;
    }
}