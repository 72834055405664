.payment-page-header{
    display: grid;
    grid-column-gap: 10px;
    justify-items: end;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 40px;
}
.payment-history .btn{
    margin: 40px auto;
}
.payment-history-item{
 display: grid;
 grid-template-columns: 1fr 1fr;
 background: #4B4B4B;
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
margin: 0 auto;
margin-bottom: 2px;
width: 85vw;
}
.payment-history-item div:first-child{
    padding: 18px 43px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
   font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    border-right: 1px solid #707171;
    color: #FFFFFF;
    
}
.payment-history-item div:last-child{
    padding: 18px 43px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    text-align: center;
    color: #01D9AB;
    word-break: break-all;
}
@media (max-width : 600px) {
    .dashboard-page-title{
        margin-top: 20px;
    }
    .payment-history-item div:first-child{
        font-size: 15px;
        padding: 12px 20px;
    }
    .payment-history-item div:last-child{
        font-size: 15px;
        padding: 12px 20px;
    }
}
.show-invoice{
    display: grid;
    width: 925px;
    margin: 40px auto;
}

.show-invoice-header{
    display: flex;
    gap: 7px;
    align-items: center;
}

.show-invoice-header div{
    font-family: gilroyRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 38px;
    letter-spacing: 0.02em;
    color: #01D9AB;
}

.show-invoice-header img{
    cursor: pointer;
}
.payment-modal-bg{
    width: 100%;
    height: 100vh;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(4px);
}
.payment-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 411px;
background: #FFFFFF;
border-radius: 4px;
max-height: 90vh;
overflow: auto;
}
.payment-modal-header{
    text-align: center;
    font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 38px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
text-transform: capitalize;
color: #01D9AB;
padding: 17px;
border-bottom: 1px solid #D7D7D7;
}
.payment-content{
    padding: 25px 48.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment-summary{
 padding: 15px;
 border: 1px solid #D6D6D6;
 width: 100%;
 margin-bottom: 25px;
}
.payment-summary-main{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 0.6px solid rgba(0, 0, 0, 0.556);
    color: #333333;
}
.payment-summary-sub{
    display: flex;
    justify-content: space-between;
 padding: 10px 0;
 color: #707171;
}
.payment-summary-main .payment-amount{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;

}
.payment-title{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    color: currentColor;
}
.payment-amount{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    text-align: right;
    letter-spacing: 0.02em;
    color: currentColor;   
}
.payment-options-heading{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #333333;
margin-bottom: 25px;
}
.payment-options-box{
    width: 100%;
    margin-bottom: 30px;
}
.payment-option{
    background: #FFFFFF;
border: 1px solid #D6D6D6;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px;

position: relative;
padding-left: 20px;
}
.payment-option:not(:last-child){
    margin-bottom: 10px;
}
.payment-option label{
    flex:1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #707171;
}
.payment-option input[type="radio"]:checked + label:before,
.payment-option input[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 13px;
    width: 24px;
    height: 24px;
    border: 1px solid #707171;
    border-radius: 100%;
    background: #fff;
}
.payment-option input[type="radio"]:checked + label:before{
    border: 1px solid #01D9AB;
}
.payment-option input[type="radio"]:checked + label:after,
.payment-option input[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 17px;
    height: 17px;
    background: #01D9AB;
    position: absolute;
    top: 16.5px;
    left: 17.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.payment-option input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    
}
.payment-option input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    
}
.quickbook{
    padding: 28px 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.quickbook-heading{
    font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: 'gilroySemiBold';
letter-spacing: 0.02em;
background: linear-gradient(180deg, #00D4A8 0%, #048F72 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
text-align: center;
margin-bottom: 30px;
}
.payment-form-input{
    width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 36px;
}
.payment-form-input label{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: 'gilroySemiBold';
    letter-spacing: 0.02em;
    
    /* Darker grey */
    
    color: #4B4B4B;
}
.payment-form-input input,.payment-form-input select{
    outline: none;
    padding: 4px 15px;
    height: 43px;
background: #FFFFFF;
border: 1px solid #707171;
border-radius: 4px;
}
.payment-form-input input:active,.payment-form-input input:focus{
    background: #EEEEEE;
/* Lighter grey */

border: 1.5px solid #707171;
}