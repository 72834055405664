header{
    font-family: gilroyMedium;
position:fixed;
top: 0;
left: 0;
z-index: 10;
padding: 30px 50px;
width: 100%;
display: grid;
grid-template-columns: min-content 1fr min-content;
align-items: center;
height: 100px;
background: #333333;

}
.logo img{
    height:41px;
}
@media (max-width : 600px) {
    .logo img{
        height: 27px;
    }
    .portal-type{
        display: none;
    }
}
.navbar {
    display: flex;
    justify-self: center;
}
.navbar-small{
    display: none;
}
.selected{
    background-color:rgba(255, 255, 255, 0.12);
}
.board-count-act{
    position: relative;
    background: #01D9AB;
    border-radius: 55px;
    height: 20px;
    width: 20px;
    color: white;
    text-align: center;
}
.navbar-item {
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    padding: 8px 8px;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
}
.navbar-item span{
    margin-left: 8px;
    font-family: gilroySemiBold;
    font-size: 14px;
    /* color: #000000 !important; */
}

.menu-box{
    display: none;
    position: relative;
}

.user-header-profile{
    width: 100%;
 display: flex;
 justify-content: flex-end;
}
.user-header-pic{
 height: 40px;
 width: 40px;
 border-radius: 50%;
 object-fit: cover;
}
.user-profile-details{
 display: flex;
/* flex-direction: column; */
align-items: start;
width: 60%;
margin: 0 8px;
}
.user-profile-name{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-profile-title{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: gilroyMedium;
    letter-spacing: 0.02em;
    color: #D6D6D6;
}
.user-header-dropdown{
    position: absolute;
    top: 115px;
    right: 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #4B4B4B;
border-radius: 4px;
width: 239px;
height: 242px;
padding: 6px;
box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.15);
}
.hide{
    display: none !important;
}
.user-header-dropdown::before{
    position: absolute;
    top: -9px;
    right: 6px;
    content: "";
    border-color: #4b4b4b transparent;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    height: 0px;
    width: 0px;
}

.search-box{
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 12px;
}
.search-input-icon{
position: absolute;
transform: translateY(37%);
left: 10px;
z-index: 2;
}
.search-input{
    width: 100%;
    outline: none;
    border: none;
   position: absolute;
   top: 0;
   left: 0;
   padding: 10px 0;

   padding-left: 50px;
   background-color: #333333;
   border: 1px solid rgba(214, 214, 214, 0.32);
box-shadow: 0px 0px 6px transparent;
border-radius: 6px;
font-weight: 600;
font-size: 16px;
line-height: 18px;
letter-spacing: 0.02em;
color: #D6D6D6;
}
.search-input:focus{
    border: 1px solid #01D9AB;
box-shadow: 0px 0px 6px rgba(0, 212, 168, 0.5);
}
.search-filters{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: baseline;
}
.search-filter{
    width: 100%;
    background-color: #333333;
    border: 1px solid rgba(214, 214, 214, 0.32);
border-radius: 6px;
display: flex;
flex-direction: column;

}
.search-filter-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
}
.search-filter-input div{
    font-family: gilroyMedium;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
color: #FFFFFF;
}
.search-filter-dropdown-item{
    padding: 12px 15px;
    font-family: gilroyMedium;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
color: #FFFFFF;
cursor: pointer;
}
.search-filter-dropdown-item:hover{
    background-color: #494848;
}
.admin-sm-header{
    display: none;
}
@media (max-width: 850px){
    
    
header{
    grid-template-columns: 1fr 1fr 1fr;
    padding:10px 30px;
    max-width: 100vw;
}

.user-header{
    justify-self: flex-end;
}
.menu-box{
    display: block;
}
.menu{
    cursor: pointer;
    display: block;
    
}
.logo-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-header-pic{
    width: 25px;
    height: 25px;
    object-fit: cover;
}
.user-header-dropdown{
    top: 90px;
    right: 5px;
}
.navbar{
    display: none;
}
.navbar-small{
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: 14px;
    left: 0px;
    top: 40px;
    background: white;
    padding: 10px 0;
    z-index: 11;
    width: 166px;
}

.search-filters{
    display: flex;
}
.navbar-small::before{
    content: "";
    height: 15px;
    width: 15px;
    background-color: white;
    position: absolute;
    top: -15px;
    clip-path: polygon(50% 0,100% 100%,0 100%);
}
.navbar-small .navbar-item{
    color: #333333;
}
.navbar-small .navbar-item span{
    color: currentColor
}
}
@media (max-width: 850px){
    .admin-sm-header{
        display: flex;
        align-items: center;
        margin: 20px ;
        position: relative;
    }
    .header-sm-logo{
        width: 111px;
        height: 27px;
        margin: 0 auto;
    }
    header{
        padding: 10px 15px;
    }
    .user-header-profile{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .user-profile-details{
        align-items: end;
        width: 80%;
    }
    .user-profile-name{
        font-family: gilroyRegular;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .user-profile-subheading{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .drop-arrow{
        margin-left: -40px;
        margin-bottom: 3px;
    }
    .user-profile-title{
        /* margin-right: 20px; */
    }
}
@media (max-width : 460px) {
    .user-profile-name{
        display: none;
    }
    .user-profile-details{
        width: 70%;
    }
    .tab-resp{
        margin-right: 20px !important;
        font-size: 12px !important;
    }
}
@media (max-width : 320px){
    .tab-resp{
        margin-right: 1vw !important;
        font-size: 10px !important;
    }
}

@media (max-width: 1100px) and (min-width: 850px){
    .logo img {
        height: 30px;
    }
    .portal-type {
        margin-bottom: 0;
    }
    .navbar-item span {
        font-size: 12px;
    }
    .navbar-item {
        padding: 6px 12px 6px 12px !important;
    }
    .portal-type {
        font-size: 14px;
    }
}

.selected-resp{
    background: #3333331F;
}

@media (max-width:1000px){
    .new-project-btn-resp{
        display: flex;
        justify-content: center;
    }
    .new-project-btn-resp .btn{
        font-size: 12px;
        padding: 8px 16px;
    }
}

@media (min-width: 1100px){
    header{
        min-height: 8rem;
    }
}