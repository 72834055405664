.notification-settings-page{
    height: 100vh;
    overflow: auto;
    width: 100%;
    padding-top: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: pink;
    z-index: 10;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(4px);
}
.notification-settings{
    padding: 46px;
    width: 830px;
    margin: 0 auto;
    background: #333333;
border: 1px solid rgba(255, 255, 255, 0.12);
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}
.notification-setting-heading{
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.notification-setting-heading-name{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    
    /* White max */
    
    color: #FFFFFF;
}
.notification-setting-heading-bar{
    height: 0px;
  flex: 1;
opacity: 0.45;
border: 1px solid #D6D6D6;
}


@media (max-width: 850px){
    .notification-settings{
        width: 100%;
        padding: 20px;
    }
}