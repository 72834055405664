.disconnect{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}
.disconnect > img{
    width: 200px;
    margin-bottom: 30px;
}
.disconnect > p{
    font-size: 30px;
    letter-spacing: 0.5px;
    font-weight: bolder;
    font-family: cursive;
    margin-bottom: 30px;
    text-align: center;
}