.modal {
    display: grid;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 50;
    padding: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(4px);
}
.modal-close{
    color: white;
    float: right;
}
.modal-popup{
    position: fixed;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(4px);

}
.modal-popup-content{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-30%);
    z-index: 51;
    background-color: #333333;
    padding: 42px 25px 42px 25px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 4px;
    display: block;
    max-height: 90vh;
    overflow: auto;
}

.modal-popup-content-resp{
    top: 35%;
}

.modal-popup-content-title{
    color: #FFFFFF;
    font-family: gilroySemiBold;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 21px;
}
.modal-popup-content-body{
    font-family: avenirMedium;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
    /* identical to box height, or 133% */
    
    text-align: center;
    
    /* Grey */
    
    color: #D6D6D6;
}
.modal-popup-content-highlight{
    color: #01D9AB;
}
.modal-popup-content-actions{
    margin-top: 21px;
 display: flex;
 justify-content: center;
 gap: 15px;
}
.modal-content {
    background-color: #333333;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 4px;
    position: relative;
}


.modal-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: end;
}

.modal-title {
    font-family: gilroyRegular;
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    margin: 25px auto;
    text-align: center;
    letter-spacing: 1.5px;
}

.modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 85px;
    margin: 10px 40px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px 0;
}
.modal-allocation-input{
    width: 100%;
}
.user-list-modal{
    z-index: 60;
}
.invite-list-modal{
    z-index: 60;
}
.invite-list-modal .modal-popup-content{
    padding: 0;
}
.invite-list-modal .modal-header{
    border-bottom: 1px solid #D6D6D6;
    align-items: baseline;
    padding: 17px 52px;
}
.invite-list-modal .auto-search{
    padding: 17px 52px;
}
.invite-list-modal .modal-footer{
    border-top: 1px solid #D6D6D6;
   margin: 0;
    padding: 17px 52px;
    display: block;
}
.title-list-modal{
    z-index: 60;
}
.title-list-modal .modal-header{
    grid-template-columns: 1fr;
    justify-items:center
}
.title-list-modal .modal-popup-content{
 background: #333333;
 width: 489px;
    padding: 30px 50px;
}
.message-title-item{
    padding: 10px 0;
   display: flex;
   gap: 10px;
   align-items: center;
   border-bottom: 1px solid #D6D6D6;
}
.message-title-item-name{
    margin-right: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 10px 0;
    /* White max */
    text-transform: capitalize;
    color: #FFFFFF;
font-family: gilroySemiBold;    
}
.add-title-box{
    padding: 10px 0;
}
.add-title{
    display: flex;
    gap: 15px;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */
cursor: pointer;
letter-spacing: 0.02em;

/* White max */
font-family: gilroySemiBold;
color: #FFFFFF;
}
.add-title-input{
    display: flex;
    gap: 10px;
    align-items: center;
}
.add-title-input input{
    flex: 1;
    outline: none;
    border: none;
    background: transparent;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

letter-spacing: 0.02em;

/* Grey */
font-family: gilroyMedium;
color: #D6D6D6;
}
.copy-link{
    font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

text-align: center;
letter-spacing: 0.02em;

color: #40BAFF;
font-family: gilroySemiBold;
display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: fit-content;
}

.notes-container{
    min-width: 557px;
}

.note-container{
    display: flex;
    padding: 10px 0px;
    gap: 10px;
}

.notes-content{
    display: grid;
    gap: 5px;
}

.notes-content input{
    padding: 10px;
    width: 500px;
    padding-bottom: 30px;
    background: #4B4B4B;
    text-align: left;
    align-items: start;
    border: none;
    color: #D6D6D6;
}

.notes-desc{
    display: flex;
}

.writer-name{
    font-family: gilroyRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.date-time{
    font-family: gilroyRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #D6D6D6;
    margin-left: 10px;
}

.note{
    width: 528px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #D6D6D6;
    text-align: justify;
    overflow-wrap: break-word;
}
.document-input-modal .files-list{
    padding: 0;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}
.document-input-modal .file-box{
    padding: 5px;
    background: #707171;
}
.modal-body-content{
    max-height: 70vh;
    overflow: auto;
}
.people-involved-header{
    margin-bottom: 20px;
}
.people-involved-title{
    margin-bottom: 0px;
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.people-involved{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #D6D6D6;
    padding: 15px 25px;
    background: #707171;
}
.modal-desc{
    background: #707171;
    padding: 15px 25px;
}
@media screen and (max-width: 850px) {
    .modal-popup{
        width: 100%;
    }
    .modal-popup-content {
        width: 90%;
    }
    .modal-content {
        margin: 80px 0px;
        padding: 0px;
        width: 90vw;
    }
    .modal {
        /* padding: 10px 0px; */
        padding: 0;
        overflow-x: hidden;
    }
    .modal-body {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .form-input-create-resp{
        max-width: 80%;
    }
    .modal-content-description{
        overflow-x: hidden;
        overflow-y: hidden;
        margin: 0;
        width: 100vw;
        height: 100vh;
    }
    .modal-description{
        height: 460px;
    }
}