.quote-top-buttons{
    display: flex;
    justify-content: center;
    /* margin-left: auto; */
    gap: 10px;
    justify-self: flex-end;
}

.quotes-footer{
    display: grid;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 60px;
    margin-bottom: 30px;
}

.quotes-footer-btn{
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
}

.quotes-footer-btn div{
    padding: 12px 16px;
    letter-spacing: 0.02em;
}
.admin-quote-project-title{
    display: flex;
    flex-direction: column;
}
.admin-quote-title{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-family: gilroySemiBold;
    color: #FFFFFF;
    letter-spacing: 0.02em;
    text-align: center;
}

.quotes-table{
    width: 100%;
    background-color: #4B4B4B;
    box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}
.quotes-table td{
    text-align: center;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

letter-spacing: 0.02em;
font-family: gilroySemiBold;
color: #D6D6D6;
padding: 10px;
}

.quotes-table tr:first-child td{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
/* identical to box height, or 118% */
padding: 20px;
letter-spacing: 0.02em;
border-bottom: 1px solid rgba(255, 255, 255, 0.12);
/* White max */
font-family: gilroySemiBold;
color: #FFFFFF;
}

.quotes-table tr td{
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.quote-project-name{
    text-decoration: none;
    border-bottom: 1px solid transparent;
    color: #FFFFFF;
}
.quote-project-name:hover{
    border-bottom: 1px solid #01D9AB;
   color: #01D9AB;
   cursor: pointer;
}
.quotes-new{
    
    font-weight: 500;
font-size: 13px;
line-height: 15px;
/* identical to box height, or 115% */


color: #FFFFFF;
background: #01D9AB;
border-radius: 33px;
padding: 5px 15px;
}

@media screen and (max-width: 768px){
    .quotes-title{
        width: 100%;
        position: absolute;
        top: -30px;
    }
    .quotes-content{
        width: 100%;
        padding-bottom: 20px;
        overflow-x: scroll;
    }
    .admin-quote-title{
        font-size: 18px;
    }
    .quote-top-buttons{
        width: 100%;
        margin-top: 30px;
    }
    .single-ppl-top-buttons{
        margin-top: 100px;
    }
    .project-body{
        margin-top: 10px;
    }
}

@media (min-width: 1100px){
    .quote-top-buttons{
        min-width: 22rem;
    }
}