.task-board{
    background: #4B4B4B;
    padding: 24px 44px;
    margin: 20px 0;
}
.task-board .board-header{
    padding: 20px 0;
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 45px;
}
.task-board-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin-bottom: 450px;
}
.task-schedule{
    background: #707171;
    display: flex;
    flex-direction: column;
}
.task-schedule-header{
  border-bottom: 1px solid hsla(0, 0%, 84%, 0.555);
  text-align: center;
  font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
padding: 15px;
}
.task-schedule-content{
flex: 1;
display: flex;
flex-direction: column;
padding: 16px;
}
.task-empty-schedule{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    color: #D6D6D6;  
    margin: auto; 
}
.task-details-modal{
    width: 812px;
}
.task-details-title{
    margin-bottom: 50px;
}
.task-details-form{
    display: grid;
    justify-content: center;
}
.new-task-detail{
    display: flex;
    gap: 34px;
    align-items: center;
    margin-bottom: 50px;
}
.new-task-detail:last-child{
    align-items: flex-start;
}
.task-detail-label{
    color: #D6D6D6;
    width: 100%;
    text-align: right;
}
.task-detail-input{
    width: 280px;
    height: 36px;
    padding: 4px 10px;
    border: 1px solid rgba(214, 214, 214, 0.25);
    border-radius: 3px;
}
.task-textarea{
    font-family: gilroyMedium;
    padding: 8px 15px;
    background: #4B4B4B;
    outline: none;
    border: none;
    letter-spacing: 0.02em;
    width: 280px;
    height: 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #D6D6D6;
    border:1px solid transparent;
    resize: none;
    border: 1px solid rgba(214, 214, 214, 0.25);
    border-radius: 3px;
}
.task{
    display:flex;
    align-items: baseline;
    justify-content: center;
    gap: 15px;
    padding: 10px 0;
   
}
.task:not(:last-child){
    border-bottom: 1px solid #D6D6D6;
}
.task-content{
    flex: 1;
    font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */
font-family: gilroySemiBold;
letter-spacing: 0.02em;

/* Grey */

color: #D6D6D6;
}
.task-content-done{
    text-decoration: line-through;
}
.highlight-date{
    position: relative;
}
.highlight-date::after{
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #0084FF;

}

@media screen and (max-width: 850px) {
    .task-board{
        padding: 4px;
        position: relative;
        padding-top: 80px;
    }
    .task-board-content{
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
    .board-title{
        width: 100%;
        position: absolute;
        top: 0;
    }
    .board-title-main{
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
        padding: 15px 0 ;
    }
    .new-task-detail{
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
    .task-detail-label{
        text-align: left;
    }
}

@media (max-width : 1000px) {
    .completed_title{
        margin-left: 120px;
    }
}
@media (max-width : 850px) {
    .completed_title{
        position: static;
        margin-left: 100px;
    }
}
@media (max-width : 600px) {
    .completed_title{
        display: none;
    }
}