
@import url("payment.css");
@import url("dashboard-project.css");
@import url("notification-settings.css");
@import url("message-board.css");
@import url("task-board.css");
@import url("calendar.css");
@import url("review-board.css");
.dashboard-page {
    background-color: #333333;
    min-height: 100vh;
    position: relative;
    padding: 0 50px;
    padding-top: 140px;
}

.empty-box img {
    width: 350px;
   display: block;
   margin: 0 auto;
}

.bg-dashboard-home {
    display: grid;
    justify-content: center;
    justify-items: center;
    text-align: center;
    align-items: center;
    /* margin-top: 50px; */
}

.bg-dashboard-home h2 {
    font-family: gilroySemiBold;
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-top: 40px;
}

@media (max-width: 800px){
    .bg-dashboard-home h2{
        margin-top: 0px;
    }
}

.bg-dashboard-home p {
    font-family: avenirLight;
    color: #D6D6D6;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
}



.tag {
    border-radius: 33px;
    width: 87px;
    height: 25px;
    padding: 5px 15px;
    font-size: 14px;
    color: #fff;
    margin: 10px;
    display: flex;
    align-items: center;
}

.profile-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px;
    color: #01D9AB;
    text-align: center;
}

.tab{
    cursor: pointer;
    padding-bottom: 10px;
}

.active {
    border-bottom: 2px solid #01D9AB;
}


.account-page-footer {
    display: flex;
    gap: 20px;
    margin-top: 60px;
    justify-content: center;
    align-items: end;
}

.project-history {
    display: grid;
    justify-content: center;
    overflow-x: scroll;    
}
.project-table {
    border-collapse: collapse;
    width: 1127px;
    color: #fff;
    margin-bottom: 20px;
}

.project-table thead {
    background-color: #707171;
    color: #fff;
    padding: 10px;
}

.project-table th {
    text-align: center;
    padding: 20px;
    font-weight: 400;
}

.project-table tbody {
    background-color: #4B4B4B;
}

.project-table td {
    text-align: center;
    padding: 20px;
}

.first-column {
    text-align: left;
}

select.form-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    /* background-image:
    linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%); */
    background-image: url(../../icons/dropdown.svg);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.drop-arrow{
    cursor: pointer;
    margin-top: 6px;
    margin-left: 10px;
}
.dashboard-page-title{
    font-weight: 600;
font-size: 34px;
line-height: 38px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
text-transform: capitalize;
color: white;
justify-self: center;
}
.form-input-select{
    position: relative;
    height: 43px;
}
.form-input-selected{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.form-input-selected img{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.form-input-select-dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}
.form-input-select-option{
    text-align: left;
    width: 100%;
    font-family: gilroyMedium;
    height: 43px;
  padding: 8px 15px;
  background-color: rgb(92 92 92);
  font-weight: 500;
font-size: 13px;
line-height: 15px;
color: #D6D6D6;
text-transform: capitalize;
}
.form-input-select-option:hover{
    background-color: rgb(123, 121, 121);
    cursor: pointer;
}
@media screen and (max-width: 850px) {
    
    .account-page-footer {
        display: none;
    }
    .project-history {
        display: block;
        margin: 0px 20px;
    }
    .dashboard-page {
        padding: 100px 20px;
    }
}
.dashboard-page{
    overflow-x: hidden;
}
.rdw-link-modal{
    background-color: #333333 !important;
}