.profile-modal{
    padding: 0 86px 0 86px;
}

.profile-content{
    position: relative;
    margin-top: 80px 0;
}

.profile-content .logo{
    position: absolute;
    top: 22px;
    left: 17px;
    width: 114px;
    height: 28px;
}

.avatar-top{
    position: relative;
    top: -50px;
}

.avatar{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.change-avatar{
    color: rgba(214, 214, 214, 1);
    font-family: gilroyRegular;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    margin-top: 13px;
    cursor: pointer;
}

.profile-input{
    display: block;
    width: 320px;
    margin-top: 6px;
}


.input-info{
    color: rgba(214, 214, 214, 1);
    width: 320px;
    font-family: gilroyRegular;
    font-size: 13px;
}

.short-bio{
    resize: none;
}

.mail-info{
    font-family: gilroyRegular;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    padding: 20px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mail-info a{
    color: rgba(214, 214, 214, 1);
    font-family: gilroyRegular;
    font-weight: 600;
    color: #FFFFFF;
}

@media (max-width: 850px) {
    .profile-modal{
        padding: 0 20px 0 20px;
    }
    .profile-input{
        width: 100%;
    }
    .input-info{
        width: 100%;
    }
}
.cross-btn{
    color: white;
    position: absolute;
    top: 65px;
    right: 15px;
    cursor: pointer;
}

.mail-list{
    width: 50%;
    text-align: justify;
}

@media (max-width: 850px){
    .mail-info{
        font-size: 16px !important;
        line-height: 25px !important;
    }
    .mail-list{
        width: 85%;
    }
}