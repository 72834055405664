.search-page{
    background: #4B4B4B;
    width: 85vw;
    margin: 0 auto;
    
}
.search-input-box{
    display: flex;
    gap: 10px;
}
.search-bar{
    height: 10rem;
    max-height: 10rem;
    background-color: #4B4B4B;
    padding: 25px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    background-blur: 10px;
}
@media (max-width: 1000px){
    .search-bar{
        height: 14rem;
        max-height: 14rem;
    }
}
.search-bar .btn-green-fill{
    height: 40px;
}
.search-box{
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 12px;
}
.search-input-icon{
position: absolute;
transform: translateY(37%);
left: 10px;
z-index: 2;
}
.search-input{
    width: 100%;
    outline: none;
    border: none;
   position: absolute;
   top: 0;
   left: 0;
   padding: 10px 0;

   padding-left: 50px;
   background-color: #333333;
   border: 1px solid rgba(214, 214, 214, 0.32);
box-shadow: 0px 0px 6px transparent;
border-radius: 6px;
font-weight: 600;
font-size: 16px;
line-height: 18px;
letter-spacing: 0.02em;
color: #D6D6D6;
}
.search-input:focus{
    border: 1px solid #01D9AB;
box-shadow: 0px 0px 6px rgba(0, 212, 168, 0.5);
}
.search-filters{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: baseline;
}
.search-filter{
    width: 100%;
    background-color: #333333;
    border: 1px solid rgba(214, 214, 214, 0.32);
border-radius: 6px;
display: flex;
flex-direction: column;

}
.search-filter-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
}
.search-filter-input div{
    font-family: gilroyMedium;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
color: #FFFFFF;
}
.search-filter-dropdown-item{
    padding: 12px 15px;
    font-family: gilroyMedium;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
color: #FFFFFF;
cursor: pointer;
}
.search-filter-dropdown-item:hover{
    background-color: #494848;
}
.search-result-heading{
    font-family: gilroySemiBold;
font-size: 22px;
line-height: 26px;
/* identical to box height, or 118% */
margin-bottom: 10px;
letter-spacing: 0.02em;

/* White max */

color: #FFFFFF;
}
.search-reviewfiles{
    display: flex;
    flex-wrap: wrap;
    white-space: none;
    gap: 15px;
}
.search-reviewfile{
    padding: 12px;
    background: #333333;
border-radius: 12px;
width: 250px;

display: flex;
flex-direction: column;
justify-content: center;
text-decoration: none;
}
.search-reviewfile img{
    height: 200px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 12px;
}
.search-reviewfile-name{
    font-family: gilroySemiBold;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 118% */
    text-transform: capitalize;
    letter-spacing: 0.02em;
    
    color: #FFFFFF;
    
}
.search-reviewfile-version{
    font-family: gilroySemiBold;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */
text-transform: capitalize;
letter-spacing: 0.02em;

/* Grey */

color: #D6D6D6;

}
.search-messages .message-title-block{
border: none;
}
@media (max-width: 1100px){
   
.search-filters{
    display: flex;
    align-items: center;
}

}

@media (max-width: 500px) {
    .search-bar {
        padding: 15px;
    }
    .search-second{
        width: 60%;
    }
    .search-thirdb{
        margin-top: 12px;
        width: 45%;
    }
}

.search-result-resp{
    position: absolute;
    width: 100%;
    background-color: #4B4B4B;
    z-index: 1;
    top: 10rem;
}

.search-bar-resp{
    position: absolute;
    width: 100%;
    z-index: 2;
}

.search-page{
    position: relative;
}

@media (max-width: 1000px){
    .search-result-resp{
        top: 14rem;
    }
}