.react-calendar__navigation{
    background: #333333;
    padding: 8px 20px 4px 20px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.react-calendar__navigation button{
    flex-grow: 0 !important;
}
.react-calendar__month-view__weekdays{
    background: #333333;
    padding: 4px 2vw;
}
.react-calendar__navigation__label{
    background: transparent;
    border: none;
    outline: none;
}
.react-calendar__navigation__arrow {
    width: 28px;
height: 28px;
cursor: pointer;
background: #FFFFFF;
border-radius: 55px;
outline: none;
border: none;
}
.react-calendar__navigation__label__labelText{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #FFFFFF;
margin: 0 5px;
}
.react-calendar__month-view__weekdays__weekday{
    font-weight: 500;
font-size: 14px;
line-height: 18px;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;
font-family: gilroyMedium;
color: #D6D6D6;
}
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}
.react-calendar__month-view__days{
    padding: 0 2vw;
    background: white;
}
.react-calendar__tile--now{
    color: #0084FF !important;
}
.react-calendar__month-view__days button{
    width: 80px;
    height: 63px;
    background: white;
    border: 1px solid #E3E3E3;
    font-weight: 600;
font-size: 22px;
line-height: 26px;
cursor: pointer;
text-align: center;
letter-spacing: 0.02em;
font-family: gilroySemiBold;
color: #333333;
}

.react-calendar__tile--active{
    background: #333333 !important;
    color:white !important;
}
@media (max-width : 850px) {
    .react-calendar__navigation{
        padding: 10px 2px;
    }
}
@media (max-width : 1000px) {
    .react-calendar__month-view__weekdays{
        padding: 10px 0;
    }
    .react-calendar__month-view__days{
        padding: 0;
    }
}