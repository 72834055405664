.review-board{
    display: flex;
    flex-direction: column;
    height: 700px;
    background: #4B4B4B;
    margin: 30px 0 ;
    width: auto;
}
.review-playground{
    display: flex;
    flex: 1;
    height:calc(700px - 57px)
}
.completed-board{
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    background: #4B4B4B;
    margin: 30px 0;
}
.review-board-header{
 display: flex;
 align-items: center;
 justify-content: space-between;
 background: #4B4B4B;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
height: 57px;
padding: 5px 27px;
grid-column: 1/3;
margin-bottom: 2px;
}
.review-header-actions{
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}
.review-header-filename{
    font-family: 'gilroySemiBold';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 18px;
color: white;
}
.review-comment-icon{
    position: relative;
}
.review-comment-new{
    position: absolute;
    top: 3px;
    right: -3px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #FF2B2B;   
}
.version-list{
    width: 230px;
    height: calc(700px - 57px);
    background: #4B4B4B;
    border-right: 1px solid rgba(214, 214, 214, 0.55);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    overflow: auto;
    position: absolute;
    z-index: 5;
}
.version-comment-list{
    width: 230px;
    height: calc(700px - 57px);
    background: white;
    border-right: 1px solid rgba(214, 214, 214, 0.55);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    overflow: auto;
    border-radius: 5px;
    position: absolute;
    z-index: 5;
}
.review-message{
    width: 100%;
    border-bottom: 1px solid #D6D6D6;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}
.review-message .comment-pin{
    height: 24px;
    width: 24px;
}
.review-message .comment-pin-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 24px;
    height: 24px;
    
border-radius: 100%;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #0085FF;
}
.review-message-replies{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.review-reply-show-text{
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    
    text-align: center;
    letter-spacing: 0.02em;
    
    color: #40BAFF;
}
.review-message-text{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* or 129% */

letter-spacing: 0.02em;

/* Darker grey */

color: #4B4B4B;

}
.review-message-header{
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
.review-message-block-profile{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.review-message-block-main{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    
    letter-spacing: 0.02em;
    
    /* Lighter grey */
    
    color: #707171;
}
.review-message-block-sub{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    
    letter-spacing: 0.02em;
    
    color: #BCBCBC;
    
}
.review-sidebar-box{
    
    display: flex;
    flex-direction: column;
    
}
.review-sidebar-version{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.02em;
    
    color: #FFFFFF;
    font-family: gilroyMedium;
}
.review-sidebar-item-active{
    
    border: 3px solid #01D9AB;
}
.review-sidebar-item{
    width: 171px;
    max-height: 239px;
    object-fit: cover;
    cursor: pointer;
}
.review-sidebar-item img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.review-sidebar-box:not(:last-child){
    margin-bottom: 30px;
}
.input-review-heading{
    margin-bottom: 35px;
}
.review-input-modal{
    padding: 40px 70px;
}
.review-input-modal-actions{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.review-input-modal .btn-grey{
    width: 100%;
  }
.review-input-modal-actions .btn{
    width: auto;
}
.review-input-playground{
    border: 1px solid #D6D6D6;
border-radius: 4px;
width: 536px;
margin-bottom: 20px;
}
.review-input-playground-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 0.2px solid #D6D6D6;
  gap: 20px;
}
.review-input-title{
    outline: none;
    border: none;
    background: #4B4B4B;
border-radius: 4px;
padding: 4px 15px;
font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;
color: #D6D6D6;
width: 308px;
height: 43px;
text-align: left;
}
.review-input-version{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.review-input-version span{
    
    font-weight: 500;
font-size: 14px;
line-height: 18px;
text-align: center;
letter-spacing: 0.02em;
color: #FFFFFF;
font-family: gilroyMedium;
}
.review-input-version-input{
    padding: 6px 15px;
    background: #4B4B4B;
border: 1px solid rgba(214, 214, 214, 0.25);
border-radius: 3px;
height: 30px;
width:36px;
font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;
color: #D6D6D6;
text-align: center;
}
.review-playground-input{

position: relative;
}
.review-playground-input-item{
    position: relative;
    height: 130px;
    width: 100%;
    background: #4B4B4B;
    border: 1px solid rgba(255, 255, 255, 0.12);
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
cursor: pointer;
}
.review-playground-input-item img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.review-input-grid {
    height: 200px;
    overflow: auto;
    padding: 20px;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 gap: 20px;
}
.review-input-item{
    position: relative;
    width: 140px
}
.review-input-item-img{
    height: 130px;
    width: 140px
}
.review-input-caption{
    text-align: center;
    color: #D6D6D6;
    word-break: break-all;
}
.review-input-remove{
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    background: rgba(255, 255, 255, 0.12);
    height: 24px;
    width: 24px;
    border-radius: 100%;
    z-index: 2;
}
.review-input-remove img{
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.close-input-review-modal{
 position: absolute;
 top: 16px;
 right: 16px;
 cursor: pointer;
}
.input-review-heading-main{
    text-align: center;
    font-size: 22px;
   line-height: 26px;
   font-family: gilroySemiBold;
   letter-spacing: 0.02em;
   color: white;
   }
   .input-review-heading-sub{
       color: #D6D6D6;
   text-align: center;
   font-weight: 500;
   font-size: 14px;
   line-height: 18px;
   font-family: gilroyMedium;
   letter-spacing: 0.02em;
   }
.review{
    background: #4B4B4B;
    flex: 1;
    height: inherit;
    width: inherit;
    position: relative;
}
.img-wrapper {
    display: flex;
    justify-content: center;
    width: inherit;
    height: inherit;
    overflow: auto;
    margin: 0 auto;
    position: relative;
}

.my-image {
    height: 100%;
    width: 100%;
    transform-origin: center;
}

.review-img{
    
    padding: 17px 33px;
}
.review-markup{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    opacity: 0.6;
    
    width:275px;
    height:138px;
}
.image-marker{
    height: inherit;
}
.image-marker__image{
    height: inherit;
    object-fit: contain;
}
/* .review-container .image-marker,.review-container img{
  height: 100%;
} */
.review-input-modal .btn-grey:not(:last-child){
    margin-bottom: 25px;
}
.review-actions{
  display: flex;
  align-items: center;
  gap: 15px;
}
.review-action{
    display: flex;
    align-items: baseline;
    gap: 5px;
    color: #FFFFFF;
    font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
}
.review-action-btn{
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    color: white;
    font-weight: 600;
    font-size: 20px;
}
.review-action input{
    border: none;
    outline: none;
    background: #333333;
    font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
padding: 5px;
color: #FFFFFF;

}
.review-action-border{
    height: 15px;
    width: 0px;
    
    opacity: 0.45;
    /* Grey */
    
    border: 1px solid #D6D6D6;
}
.review-action-pagecount{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.review-action-pagecount-input{
 width: 50%;
}
.review-action-zoom-input{
    width: 40px;
    text-align: center;
}
.review-action-pagecount-input input{
    width: 100%;
    text-align: center;
}
.review-image-scroll{
    position: absolute;
    bottom: 100px;
    left: 48%;
    padding: 5px;
    display: flex;
    justify-content: center;
    gap: 10px;
    background: #333333;
border-radius: 33px;
width: 93px;
height: 35px;
color:#D6D6D6;
margin: 0 auto ;
}
.review-image-scroll button{
    border: none;
    outline: none;
    background-color: transparent;
}
.review-image-scroll button svg{
    vertical-align: bottom;
}
.btn-review-image-edit{
    border-radius: 100%;
    height: 60px;
    width: 60px;
    position: absolute;
    right: 30px;
    bottom: 30px;
}
.comment{
    width: 40px;
    height: 40px;
    position: relative;
}
.comment-pin{
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 100%;
    padding: 2px;
    position: relative;
    cursor: pointer;
}
.comment-pin-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30px;
    height: 30px;
    
border-radius: 100%;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 14px;
text-align: center;
line-height: 26px;
color: #0085FF;
}
.comment-pin-active{
    background: rgba(162, 198, 232, 0.55);
}
.comment-block{
    position: absolute;
    top: 100%;
    left: 100%;
    
    width: 405px;
background: #FFFFFF;
box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.15);
border-radius: 0px 8px 8px 8px;
}
.comment-header{
    padding: 8px 15px;
    border-bottom: 1px solid #D6D6D6;
}
.comment-actions{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}
.comment-message-block-more{
    position: relative;
}
.comment-action-more-drop::before{
    position: absolute;
    top: -9px;
    right: 6px;
    content: "";
    border-color: #4b4b4b transparent;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    height: 0px;
    width: 0px;
}
.comment-action-more{
    position: relative;
    height: 20px;
    width: 20px;
}
.comment-action-more img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.comment-action-more-drop{
position: absolute;
top: 150%;
right: -6px;
background: #4B4B4B;
border-radius: 4px;
padding: 7px 17px;
display: flex;
flex-direction: column;
gap: 5px;
}

.comment-action-more-drop-item{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    font-family: gilroyMedium;
    letter-spacing: 0.02em;
    width: max-content;
    /* Grey */
    cursor: pointer;
    color: #D6D6D6;
}
.comment-message-block{
    padding: 0 15px;
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}
.comment-message-block-profile-img{
    width: 32px;
height: 30px;

background: #BA68C8;
border-radius: 52px;
font-weight: 600;
font-size: 16px;
line-height: 30px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
text-transform: capitalize;
text-align: center;
color: white;
}
.comment-message-block-profile-img img{
    width: 32px;
    height: 30px;
    border-radius: 52px;
    object-fit: cover;
}
.comment-message-block-details{
    width: 100%;
   display: flex;
   flex-direction: column;
   gap: 4px;
}
.comment-message-block-header{
   display: flex;
   align-items: start;
   
}
.comment-message-block-header-content{
    flex: 1;
}
.comment-message-block-profile{
    display: flex;
    align-items: baseline;
    gap: 3px;
}
.comment-message-block-main{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    color: #4B4B4B;
    text-transform: capitalize;
}
.comment-message-block-sub{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    font-family: gilroySemiBold;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #A2A2A2;
    
}
.comment-message-block-content{
 
font-family: gilroySemiBold;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* or 129% */

letter-spacing: 0.02em;

/* Darker grey */

color: #4B4B4B;


}
.comment-message-block-input{
    background: rgba(214, 214, 214, 0.5);
border-radius: 4px;
display: flex;
align-items: center;
padding: 8px;
}
.comment-message-block-input input{
    background-color: transparent;
    outline: none;
    border: none;
    flex: 1;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
letter-spacing: 0.02em;

/* Darker grey */

color: #4B4B4B;
}
.comment-message-submit{
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.edit-comment{
    background: rgba(214, 214, 214, 0.5);
    border-radius: 4px;
    padding: 8px 6px;
}
.edit-comment textarea{
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
max-height: 200px;
/* or 129% */

letter-spacing: 0.02em;

/* Darker grey */
font-family: gilroySemiBold;
color: #4B4B4B;
}
.edit-comment-actions{
    margin-top: 8px;
 display: flex;
 justify-content: flex-end;
 gap: 10px;
}
.comment-list{
    padding: 15px;
    max-height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
.comment-list .comment-message-block{
    padding: 0;
}
.edit-btn-fill{
    padding: 8px 12px;
    border: none;
    outline: none;
    background: #0085FF;
    border-radius: 4px;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;

/* White max */

color: #FFFFFF;
}
.edit-btn-outline{
    padding: 8px 12px;
    border: none;
    outline: none;
    border: 1px solid #0085FF;
    background-color: transparent;
    border-radius: 4px;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;

color: #0085FF;

}
.FIE_root {
    background: #4b4b4b !important;
    color: white !important;
}
.FIE_root *{
    color: white;
} 
.FIE_tab{
    background: transparent !important;
    color: white !important;
}
.fBEhPu[aria-selected="true"]{
    background-color: rgb(121, 121, 121) !important;
}
.fBEhPu[aria-selected="true"] *{
    color: white !important;
}
.FIE_resize-width-option,.FIE_resize-height-option,.FIE_resize-reset-button{
    background: transparent !important;
    color: white !important;
}
.FIE_topbar-save-button{
    background: linear-gradient(180deg, #00D4A8 0%, #048F72 100%) !important;
}
.FIE_canvas-node{
    background: #636161 !important;
}
.SfxSelect-Label{
    color: black;
}
.cEwkxb[aria-selected="true"],.cEwkxb:hover{
    background-color: rgb(121, 121, 121) !important;
    
}
.cEwkxb[aria-selected="true"]{
    color: white;
}
.FIE_annotation-controls-overlay{
    background: rgb(75 75 75) !important;
}
.cEwkxb[aria-selected="true"] * {
    color: white !important;
}
.FIE_image-tool-add-option-button{
    background: #797979;
}

.pintura-editor {
    --color-background: 75, 75, 75;
    --color-foreground: 255, 255, 255;
}
.download-img-btn{
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.markup-edit-btn{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 980px) {
    .review-board-header{
        flex-direction: column;
        height: fit-content;
       }
       .member-hide{
        display: none;
       }
       .review-header-actions{
        margin: 10px 0;
       }
}