.profile-page{
    font-family: gilroyRegular;
}
.profile-header{
    font-family: gilroyRegular;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
}

.profile-header div{
    padding-bottom: 11px;
}
.cancelled {
    background-color: #E95F57;
}

.pending {
    background-color: #D37A27;
}

.status {
    display: flex;
    justify-content: center;
}

::-webkit-scrollbar {
    margin-top: 100px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: #D6D6D6;
    border-radius: 10px;
}


.view-file  {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #40BAFF;
}

.view {
    text-decoration: none;
    color: rgba(64, 186, 255, 1);
    margin: 0 5px;
    cursor: pointer;
}

.sign{
    text-decoration: none;
    background: transparent;
    outline: none;
    color: #01D9AB;
    border: 2px solid #01D9AB;
    padding: 3px 10px;
    border-radius: 5px;
    margin: 0 5px;
    display: inline-block;
    cursor: pointer;
}

.pay{
    background-color: transparent;
    border: none;
    outline: none;
    color: #01D9AB;
    border: 2px solid #01D9AB;
    padding: 3px 10px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
}
.pay-inactive,.sign-inactive{
    color: #D6D6D6;
    border: 2px solid #D6D6D6;
    cursor: default;
    cursor: default;
}
.profile-tag {
    display: flex;
    justify-content: center;
}

@media (max-width: 850px) {
    .profile-header{
        font-size: 18px;
        line-height: 21px;
    }
    ::-webkit-scrollbar {
        background: #4B4B4B;
    }
}