.message-board{
    background: #4B4B4B;
    padding: 24px 44px;
    margin-top: 20px;
}
.board-header{
 display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 15px;
}
.board-header-btn-block{
    display: flex;
    gap: 10px;
    align-items: center;
}
.board-title{
    text-align: center;
}
.board-title-main{
    font-weight: 600;
font-size: 28px;
line-height: 32px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
text-transform: capitalize;
color: #FFFFFF;
text-align: center;
}
.board-title-sub{
    font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
letter-spacing: 0.02em;
color: #D6D6D6;
text-align: center;
}

.message-block-header{
 position: relative;
 height: 50px;
    margin: 50px 0;
}
.message-block-header-hr{
position: absolute;
top: 50%;
left: 0;
transform: translateY(-50%);
height: 1px;
background-color: #D6D6D6;
width: 100%;
}
.message-block-header-content{
    border: 1px solid #D6D6D6;
    border-radius: 23px;
    padding: 10px 25px;
    font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #FFFFFF;
position: absolute;
background: #4b4b4b;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.message-block-item{
    text-decoration: none;
    display: flex;
    gap: 10px;
    padding: 10px ;
    /* margin-bottom: 30px; */  
}
.message-block-item:not(:last-child){
    border-bottom: 1px solid #D6D6D6;
}

.message-block-item-content-header{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.message-block-item-content .documents{
    color: white;
}
.message-block-item-title{
    text-decoration: none;
    font-weight: 600;
font-size: 22px;
line-height: 26px;
letter-spacing: 0.02em;
font-family: gilroySemiBold;
text-transform: capitalize;
color: #FFFFFF;
}

.message-block-item-time{
    font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
margin-left: 5px;
}
.message-notification{
    width: 27px;
height: 27px;
text-align: center;
background: #01D9AB;
border-radius: 55px;
margin-left: auto;
}
.message-block-item-message{
    font-family: avenirMedium;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: #D6D6D6;
    gap: 10px;
}
.message-title-heading{
    font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
}
.message-title-input{
 outline: none;
 border: none;
 background: transparent;
 width: 100%;
 border-bottom: 1px solid #D6D6D6;
 font-weight: 500;
font-size: 18px;
line-height: 24px;
font-family: avenirMedium;
color: #FFFFFF;
padding-bottom: 5px;
}
.message-category-select{
    margin: 15px 0;
    position: relative;
}
.message-category-selected{
    border: 1px solid #D6D6D6;
border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 8px 15px;
gap: 8px;
width: fit-content;
font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
text-align: center;
letter-spacing: 0.02em;
color: #D6D6D6;
margin-bottom: 1px;
cursor: pointer;
}
.message-category-active{
    color: #4B4B4B;
    background: white;
}
.message-category-select-dropdown{
    position: absolute;
    background: white;
    z-index: 10;
    background: #FFFFFF;
box-shadow: 0px 12px 18px 2px rgba(0, 0, 0, 0.05);
border-radius: 4px;
width: fit-content;
}
.message-category-item{
    padding: 4px 15px;
    cursor: pointer;
    text-transform: capitalize;
}
.message-editor{
    background: #707171;
   
}
.document-board{
    background: #4B4B4B;
    padding: 24px 44px;
    margin-top: 20px;
}
.message-modal .modal-description{
    min-height: 200px;
    height: auto;
  }
  .message-editor,.reply-editor {
    height: 100%;
      overflow: inherit !important;
  
  }
.rdw-suggestion-dropdown{
    min-width: 150px !important;
}
.message-editor-toolbar{
    background: rgba(255, 255, 255, 0.14);
    margin: 0 !important;
    border: none !important;
}
.message-editor-toolbar button{
    background: transparent !important;
    border: none !important;
    color: white;
}
.reply-toolbar{
    display: none !important;
}

.message-editor-editor{
    background: #707171;
}
.document-board .board-header{
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 45px;
    padding-bottom: 20px;
}
.document-board-grid{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 35px;
    margin: 20px 0;
}
.document-board-file{
    text-decoration: none;
    width: 177px;
    height: 202px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px 40px;
}
.document-board-file img{
    height: 71px;
    width: 71px;
}
.document-board-filename{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-family: gilroyMedium;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4B4B4B;
    overflow: hidden;
    word-break: break-all;
    hyphens: auto;
}
.message-notify{
    margin: 20px 0;
}
.message-notify-heading{
    font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

letter-spacing: 0.02em;
margin-bottom: 10px;
/* White max */
font-family: gilroySemiBold;
color: #FFFFFF;
}
.message-notify .form-input-type{
    margin-bottom: 5px;
    margin-left: 0;
}
.message-file-block{
    background: #707171;
    position: relative;
}
.message-file-block .files-list{
    padding: 20px 0;
}
.message-file-block .file-box{
    background: #515151;
    padding: 10px;
    color: white;
}
.message-add-file{
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
font-size: 14px;
line-height: 18px;
width: fit-content;
cursor: pointer;
letter-spacing: 0.02em;
font-family: gilroyMedium;
color: #D6D6D6;

}
.message-add-file-block{
    padding: 25px;
    position: absolute;
    bottom: 135%;
    background: #4B4B4B;
border: 1px solid rgba(255, 255, 255, 0.12);
box-shadow: 4px 2px 14px rgba(0, 0, 0, 0.15);
border-radius: 4px;
}
.message-add-file-type{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */
cursor: pointer;
letter-spacing: 0.02em;

/* White max */
font-family: gilroyMedium;
color: #FFFFFF;
}
.message-add-file-type:not(:last-child){
    margin-bottom: 15px;
}
.message-show-reply{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
text-align: center;
letter-spacing: 0.02em;
color:white;
margin: 10px 0;
cursor: pointer;
}
.reply-highlight{
    color: #40BAFF;
    cursor: pointer;
}
.reply-box{
    background: rgba(112, 113, 113, 0.45);
    border: 1px solid rgba(214, 214, 214, 0.25);
    border-radius: 4px;
    min-width: 487px;
    min-height: 79px;
    padding: 15px;
}
.reply-box-input{
   outline: none;
   border: none;
   background: transparent;
   font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
font-family: avenirMedium;
margin-bottom: 10px;
color: #FFFFFF;
}
.reply-box .message-file-block{
    display: flex;
    justify-content: space-between;
    background: transparent;
    align-items: center;
}
.reply-box .file-box{
    background: rgba(255, 255, 255, 0.12);
    padding: 5px 10px;
}
.highlight-conversation{
    background: rgba(255, 255, 255, 0.12);
border-radius: 4px;
}
.conversation-file-block{
    background: transparent;
}
.conversation-file-block .file-box{
    background-color: #707171;
    padding: 10px;
    text-decoration: none;
}
@media screen and (max-width: 850px) {
    .message-board{
        padding: 4px;
        position: relative;
        padding-top: 60px;
    }
    .document-board{
        padding: 4px;
        position: relative;
        padding-top: 80px;
    }
}