@import url("pdf.css");
.form-input-box{
  width: 100%;
}
.form-input{
  width: 100%;
}
.form-label{
  font-family: gilroySemiBold;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 6px;
  color: #FFFFFF;
}
.form-input-box:not(:last-child)
{
  margin-bottom: 22px;
}
.last-form-input-box{
  margin-bottom: 12px;
}
.form-input,.PhoneInputInput,.default-form-input{
  font-family: gilroyMedium;
  height: 43px;
padding: 8px 15px;
background: #4B4B4B;
outline: none;
border: none;
letter-spacing: 0.02em;
width: 100%;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #D6D6D6;
border:1px solid transparent;
border-radius: 4px;
}
.default-form-input{
  pointer-events: none;
}
.form-input:focus{
  border: 1px solid #00D4A8;
}
.input-invalid{
  border: 1px solid #FF3333 !important;
}
.btn{
  height: 48px;
  border-radius: 4px;
  padding: 8px 20px;
  border: none;
  outline: none;
 cursor: pointer;
text-align: center;
letter-spacing: 0.02em;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
font-family: gilroyMedium;
font-size: 14px;
}
.btn span{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: gilroyMedium;
}
.btn {
  gap: 8px;
}

.btn-grey{
  background: #707171;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.btn-green-fill{
  background: linear-gradient(180deg, #00D4A8 0%, #048F72 100%);
}
.btn-green-fill:hover{
  background: #04A382;
}

.btn-green-outline{
  background: transparent;
  border: 1px solid #00D4A8;
  color: #00D4A8;
}
.btn-grey-outline{
  background: transparent;
  border: 1px solid #D6D6D6;
  color: #D6D6D6;
}
.login-btn{
  margin-top: 35px;
}
.auth-notification{
  font-family: gilroySemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
font-size: 16px;
line-height: 18px;
letter-spacing: 0.02em;
margin: 12px auto;
padding: 8px 20px;
border-radius: 31px;
width: fit-content;
}
.dashboard-page .auth-notification{
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.auth-form .auth-notification{
  margin: 20px 0;
}
.auth-notification img{
  margin-right: 10px;
  height: 20px;
}
.auth-notification-success{
  background: rgba(114, 196, 137, 0.23);

color:#27DC65;
}
.auth-notification-fail{
  background: rgba(255, 116, 116, 0.44);
  color: #FF3333;
}
.ppl-tabs{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 40px;
}
.payment-tabs{
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 40px;
}
.tab{
  font-weight: 600;
font-size: 22px;
line-height: 26px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
border-bottom: 1px solid transparent;
text-align: center;
}
.tab-active{
  color: #01D9AB;
  border-bottom: 1px solid #01D9AB;
}
.modal-close{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modal-link-body{
  width: 687px;
  justify-content: flex-start;
}
.modal-link{
  text-align: left;
  justify-content: flex-start;
}
.modal-description{
  background: #707171;
  height: 382px;
}
.editorClassName{
  padding: 10px;
}
.modal-link-header{
  font-size: 22px;
  line-height: 26px;
  margin-top: 22px;
  margin-bottom: 21px;
  text-align: center;
}
.home-actions{
  display: flex;
  margin-bottom: 30px;
}
.home-tabs{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.home-tabs .tab{
  font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
border-bottom: 2px solid transparent;
}

.home-tabs .tab:not(:last-child)
{
  margin-right: 30px;
}

@media (max-width:450px) {
  .home-tabs .tab{
    margin-right: 1vw !important;
  }
}

.home-tabs .tab-active{
  color: #01D9AB;
  border-bottom: 2px solid #01D9AB;
}

.sort-btn{
  width: 24px;
  height: 24px;
}

.sort-action img{
  width: 24px;
  height: 24px;
}
.sort-action span{
  font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemibold;
letter-spacing: 0.02em;
color: #FFFFFF;
padding: 4px;
}

.sort-action{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.sort-options{
  width: 135px;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  right: 30px;
  margin-top: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 12;
}
.sort-options::before{
  position: absolute;
  top: -9px;
  right: 6px;
  content: "";
  border-color: white transparent;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  height: 0px;
  width: 0px;
}
.sort-option{
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: gilroySemiBold;
  letter-spacing: 0.02em;
  color: #333333;
  cursor: pointer;
}
.card-grid{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(341px, 1fr));
  grid-gap: 46px;
  margin-bottom: 40px;
}
.dashboard-page .card-grid{
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 20px;
}
.project-grid-item{
  position: relative;
  margin-top: 15px;
}
.project-pin{
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
.project-card{
  text-decoration: none;
  position: relative;
  border: 2px solid #4B4B4B;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
    flex-direction: column;
    height: 250px;
}
.project-card svg{
position: absolute;
right: 15px;
top: 15px;


}
.project-card-top{
  background: #4B4B4B;
  padding: 20px 16px;
}
.project-card-bottom{
padding: 20px 16px;

}
.project-card-bottom div{
  font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
color: #D6D6D6;
}
.project-card-bottom div:not(:last-child)
{
  margin-bottom: 10px;
}
.project-card-name{
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  font-family: gilroySemiBold;
  letter-spacing: 0.02em;
  text-transform: lowercase;
  color: #FFFFFF;
}
.project-card-client{
  font-weight: 600;
font-size: 16px;
line-height: 18px;
font-family: gilroySemiBold;
letter-spacing: 0.02em;
color: #D6D6D6;
margin: 12px 0;
text-transform: lowercase;
}

.project-card-description{
  display: -webkit-box;
  font-weight: 500;
font-size: 13px;
line-height: 15px;
color: #D6D6D6;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
font-family: avenirMedium;
}
.project-card-bottom span{
  font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
letter-spacing: 0.02em;
color: #D6D6D6;
text-transform: lowercase;
  display: inline-block;
}

.project-card-client::first-letter,.project-card-description::first-letter,.project-card-name::first-letter,.project-card-bottom span::first-letter{
  text-transform:uppercase;
}
.form-input-type{
  display: flex;
  align-items: flex-start;
  margin-bottom: 21px;
  position: relative;
  margin-left: 20px;
}
.form-input-type input{
  margin-right: 30px;
  margin-top: 5px;
}
.form-input-label-type-main{
  font-weight: 600;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 112% */

letter-spacing: 0.02em;
font-family: gilroySemiBold;
color: #FFFFFF;
margin-bottom: 8px;
}
.form-input-label-type-sub{
  font-weight: 500;
font-size: 14px;
line-height: 18px;
font-family: gilroyMedium;
letter-spacing: 0.02em;
color: #D6D6D6;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #01D9AB;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


[type="checkbox"]:not(:checked) + label:before {
    content:'';
    position: absolute;
    top: 2px;
    left: 0px;
  background-color: white;
  padding: 8px;
  border: solid transparent;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}
[type="checkbox"]:checked + label:before{
    content:'';
    position: absolute;
    top: 2px;
    left: 0px;
  background-color: #01D9AB;
  padding: 8px;
  border: solid transparent;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}
[type="checkbox"]:checked + label:after{
    content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.message-modal{
  margin-top: 10px;
  padding: 20px;
  background: #707171;
}

.rdw-editor-toolbar .rdw-option-wrapper{
  background: rgba(255, 255, 255, 0.14);
  border: none;
  box-shadow: none;
}

.rdw-editor-toolbar .rdw-dropdown-wrapper{
  background: rgba(255, 255, 255, 0.14);
  border: none;
  box-shadow: none;
}

.rdw-editor-toolbar .rdw-option-active{
  background: #fff;
  border: none;
  box-shadow: none;
}

.portal-type{
  font-family: gilroyRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.09em;
  color: #D6D6D6;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}

.app{
  position: relative;
}

.spinner-container{
  display: flex;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 200px;
  height: 200px;
  border: 10px solid #00D4A8;
  /* border: 10px solid #f3f3f3; */
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.production-dashboard{
  margin: 20px 30px;
}

.production-body{
  margin: 30px;
}

.btn-hide{
  visibility: hidden;
}
.sortNew{
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
}
@media (max-width:450px){
  .home-actions{
    width: 106% !important;
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (max-width:850px){
  .home-tabs{
    margin-bottom: 20px;
  }
  .form-label{
    /* font-weight: 600; */
font-size: 14px;
line-height: 16px;
  }
  .form-input{
    font-weight: 500;
font-size: 12px;
line-height: 18px;
height: 40px;
  }
  .form-input-box:not(:last-child){
    margin-bottom: 18px;
  }
  .btn{
    height: 44px;
    font-family: gilroyMedium;
  }
  .btn span{
    font-weight: 500;
    font-size: 12px;
line-height: 18px;
  }
  .btn img{
    height: 13px;
    width: 20px;
  }
.modal-link-body{
    width: 100%;
  }
.home-actions{
  align-items: start;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.ppl-tabs .tab{
  font-size: 14px;
  line-height: 16px;
}
.card-grid{
  margin-top: 10px;
}
.sm-add-btn{
  margin-left: 10px;
}
.sm-sort-btn{
  margin-right: 10px;
}
.message-modal{
  padding: 15px 5px;  
}
.login-btn{
  margin-top: 30px;
}
.auth-form .auth-notification{
  font-size: 12px;
  margin-top: 40px;
}
/* .sortNew{
  position: relative;
  top: 65px;
} */
.home-actions{
  display: flex;
  /* align-items: center; */
}
.card-grid{
  /* justify-items: center; */
}
.btn-resp{
  max-width: 73px;
  font-size: 12px;
}
}
@media (max-width: 680px){
  .project-grid-item{
    width: 89vw;
  }
}
@media (max-width:450px){
    .home-tabs > .tab{
      font-size: 13px;
      font-weight: bold;
    }
    .sortNew{
      justify-content: center;
    }
}

.login-btn-resp img{
  height: 14px;
  width: 24px;
}

@media (max-width: 800px){
  .login-btn-resp img{
    height: 13px;
    width: 20px;
  }
}
.noReplyMessage{
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 40px;
}